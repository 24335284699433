import React, { useState} from 'react'
import { Radio,Divider} from 'antd'

import { CreditEnum } from '../../../PersonalCenter/cpns/TodoList/config/constant'

import CreditHistoryList from '../CreditHistoryList'

export default function Index() {
    const [orederBy,setOrderBy]=useState('asc')
    const [creditType,setCreditType]=useState()
    const onChange = (e) => {
        setOrderBy(e.target.value);
      };
    const onChangeHistoryType=(e)=>{
        setCreditType(e.target.value)
    }
  return (
    <div key={orederBy+creditType}>
      <Radio.Group value={creditType} onChange={onChangeHistoryType} buttonStyle="solid">
        <Radio.Button value={CreditEnum.SIGN_IN.creditType}>签到赠送</Radio.Button>
        <Radio.Button value={CreditEnum.USER.creditType}>用户赠送</Radio.Button>
        <Radio.Button value={CreditEnum.BROWSE.creditType}>浏览文章</Radio.Button>
        <Radio.Button value={CreditEnum.REVIEW.creditType}>评论文章</Radio.Button>
        <Radio.Button value={CreditEnum.STAR_ARTICLE.creditType}>点赞文章</Radio.Button>
        <Radio.Button value={CreditEnum.FOLLOW.creditType}>关注别人</Radio.Button>
        <Radio.Button value={CreditEnum.SYSTEM.creditType}>系统赠送</Radio.Button>
      </Radio.Group>
      <Divider />
      <Radio.Group onChange={onChange} value={orederBy}>
      <Radio value={'asc'}>最新</Radio>
      <Radio value={'desc'}>最旧</Radio>
    </Radio.Group>
    <CreditHistoryList orederBy={orederBy} creditType={creditType}/>
    </div>
  )
}
