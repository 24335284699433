import React,{useState,useRef, useEffect} from 'react';
import { useNavigate,useSearchParams } from 'react-router-dom';

import { 
  LikeOutlined, 
  EyeOutlined, 
  MessageOutlined ,
  ClockCircleOutlined,
  UserOutlined} from '@ant-design/icons'
import { message} from 'antd'

import { getArticleStar,getUnArticleStar } from '../../../../service/article';

import{IconText} from '../../../../utils/IconText'
import {formatUtcString} from '../../../../utils/date'
import { postAddCreditFun } from '../../../../utils/postAddCredit';

import { CreditEnum } from '../../../PersonalCenter/cpns/TodoList/config/constant';


import PubSub from 'pubsub-js'

import './style.less'

const App = (props) => {
  const [articleData,setArticleData]=useState(props.articleData)
  const navigate=useNavigate()
  const [search,]=useSearchParams()
  const isAdvert=search.get('advert')
  const [isStar, setIsStar] = useState(articleData.liked)
  const starNumRef = useRef(articleData.articleStar)
  const lastCreditTodoListMap=JSON.parse(localStorage.getItem('lastCreditTodoListMap'))
  useEffect(()=>{
    const setCommentNumsTimer=PubSub.subscribe('setCommentNums',(msg,type)=>{
      switch(type){
        case 'add':
          setArticleData({...articleData,articleComments:articleData.articleComments+1})
          break
        case 'sub':
          setArticleData({...articleData,articleComments:articleData.articleComments-1})
          break
        default:
          break
      }
    })
    return ()=>{
      PubSub.unsubscribe(setCommentNumsTimer)
    }
  },[])//eslint-disable-line
  const getArticleStarFun = async (articleId) => {
    if (isStar) {
      const { data, success } = await getUnArticleStar({ articleId })
      if (success) {
        message.info(data)
        setIsStar(false)
        starNumRef.current--
      }
    } else {
      const { data, success } = await getArticleStar({ articleId })
      if (success) {
        if(isAdvert ){
          if(
            lastCreditTodoListMap[CreditEnum.STAR_ARTICLE.STAR_ARTICLE].status==='done'
            || lastCreditTodoListMap[CreditEnum.STAR_ARTICLE.STAR_ARTICLE].nums===2
          ) message.info('今日点赞任务已经完成 ')
          else{
          postAddCreditFun(
            CreditEnum.STAR_ARTICLE.creditType,
            CreditEnum.STAR_ARTICLE.quantity,
            CreditEnum.STAR_ARTICLE.message,
            CreditEnum.STAR_ARTICLE.STAR_ARTICLE)
        }
        }
        message.success(data)
        setIsStar(true)
        starNumRef.current++
      }
    }
  }
return(
<>
<h1>{articleData.articleTitle}</h1>
<div className="article_info_active_box">
      <span className='cursor'>
        <IconText clickFun={()=>navigate(`/usercollect/${articleData.author?.userId}`)} icon={UserOutlined} text={articleData.author?.nickName || '小享'} key="article_info_active_1" />
      </span>
        <IconText icon={ClockCircleOutlined} text={formatUtcString(articleData.createTime)} key="article_info_active_2" />
        <span className='pc_active cursor'><IconText clickFun={()=>getArticleStarFun(articleData.articleId)} isStar={isStar} icon={LikeOutlined} text={starNumRef.current} key="article_info_active_3" /></span>
        <span className='pc_active cursor'><IconText icon={MessageOutlined} text={articleData.articleComments} key="article_info_active_4" /></span>
        <IconText icon={EyeOutlined} text={articleData.articleViews} key="article_info_active_5" />
  </div>
</>
)
}
export default App;