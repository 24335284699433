import { Card } from 'antd'
import React, { useEffect, useState } from 'react'

import './style.less'

import { getArticleTop } from '../../service/article'

const App = (props) => {
  const [articleList,setArticleList]=useState([])
  useEffect(()=>{
    ;(async ()=>{
        const {data,success}=await getArticleTop({limit:5,orderBy:props.orderBy+'.desc'})
        if(success){
            setArticleList([...data])
        }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <>
      <Card
        size="small"
        title={props.title}
        style={{
          width: 280,
          marginBottom:12
        }}
      >
        {
            articleList.map((item,index)=>{
              // eslint-disable-next-line
                return <a key={index} className='aside_link'><span>{props.iconNode}{item[props.orderBy]}</span>{item.articleTitle}</a>
            })
        }
      </Card>
    </>
  )
}

export default App
