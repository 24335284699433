// 根据点赞评论数返回颜色值
export function activeColorByNum(num){
    let color;
    if(num<5)color='#FFCDD2'
    else if(num>5 && num<10) color='#E57373'
    else color='#E53935'
    return color
}

//格式化文章预览
export function splitContentView(str){
    return str.length>50 ? str.slice(0,50) : str
}