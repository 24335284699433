import React from 'react'
import { useNavigate ,useNavigationType} from 'react-router-dom'

import {PageHeader} from 'antd'

export default function Back() {
    const navigateType=useNavigationType()
    const navigate=useNavigate()
    const back=()=>{
        if(navigateType === 'POP') navigate('/')
        else navigate(-1)
    }
  return (
    <PageHeader
    className="site-page-header"
    onBack={back}
    subTitle="返回"
  />
  )
}
