import { Avatar, List,message} from 'antd';
import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import { 
    PropertySafetyTwoTone,
    GiftTwoTone,
    DatabaseTwoTone
    } from '@ant-design/icons'

import { getCredit } from '../../../../service/credit';


const App = () => {
  const navigate=useNavigate()
  const [creditNums,setCreditNums]=useState(0)
  useEffect(()=>{
      //获取用户总积分
      ;(async()=>{
          const {data,success}=await getCredit()
          if(success){
              setCreditNums(data)
          }else message.error(data)
      })()
  },[])
    const ListData = [
        {
            title: '我的积分',
            description:'余额 '+creditNums,
            icon:<PropertySafetyTwoTone twoToneColor="#1DA57A"/>,
            fn:()=>navigate('/credit/center/optionlist')
        },
        {
            title: '积分兑换 (开发中...)',
            description:"曝光自己",
            icon:<GiftTwoTone twoToneColor="#1DA57A"/>,
            fn:()=>navigate('/credit/center/buy')
        },
        {
            title: '积分历史记录',
            description:`积分明细`,
            icon:<DatabaseTwoTone twoToneColor="#1DA57A"/>,
            fn:()=>navigate('/credit/center/history')
        },
    ];
    return (
        <div className='option_list_box'>
        <List
          itemLayout="horizontal"
          dataSource={ListData}
          renderItem={(item) => (
            <span onClick={()=>item.fn && item.fn()}>
              <List.Item>
              <List.Item.Meta
                avatar={<Avatar size="large" style={{'backgroundColor': '#ffff'}} icon={item.icon} />}
                title={item.title}
                description={item.description}
              />
            </List.Item>
            </span>
          )}
        />
        </div>
      );
}
export default App;