import { LikeFilled, LikeOutlined } from '@ant-design/icons'
import { Avatar, Comment, message, Button, Input } from 'antd'
import React, { createElement, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import PubSub from 'pubsub-js'

import CommentList from '../CommentList'

import './style.less'

import { getStarComment, getUnStarComment, getDelComment, postEditComment, postComment } from '../../../../../../service/comment'

const App = (props) => {
  const { TextArea } = Input
  const navigate = useNavigate()
  const { commentData } = props
  const { id: articleId } = useParams()
  const { commentId: currentCommentId } = commentData
  const [likes, setLikes] = useState(commentData.commentStar)
  const [action, setAction] = useState(commentData.liked)

  const [isShowEdit, setIsShowEdit] = useState(false)
  const [commentContent, setCommentContent] = useState(commentData.commentContent)

  const [isShowReply, setIsShowReply] = useState(false)
  const [replyCommentContent, setReplyCommentContent] = useState('')

  const editInputRef = useRef()
  const replyInputRef = useRef()
  useEffect(()=>{
    editInputRef.current && editInputRef.current.focus({cursor:'end'})
    replyInputRef.current && replyInputRef.current.focus({cursor:'end'})
  })

  const like = async () => {
    if (commentData.owner) return message.error('不能给自己点赞')
    const { data, success } = await getStarComment({ commentId: currentCommentId })
    if (success) {
      setLikes(likes + 1)
      setAction(true)
      message.success(data)
    } else message.error(data)
  }
  const cancelLike = async () => {
    const { data, success } = await getUnStarComment({ commentId: currentCommentId })
    if (success) {
      setLikes(likes - 1)
      setAction(false)
      message.success(data)
    } else message.error(data)
  }
  const delComment = async () => {
    const { data, success } = await getDelComment({ commentId: currentCommentId })
    if (success) {
        props.delComment(commentData)
        PubSub.publish('setCommentNums','sub')
        PubSub.publish('setCommentNums_phone','sub')
    } else message.error(data)
  }
  const editComment = async () => {
    if (commentContent.trim().length < 1) return message.error('评论内容不能为空')
    const { data, success } = await postEditComment({ commentId: currentCommentId, commentContent })
    if (success) {
      setIsShowEdit(false)
      message.success('修改成功')
      props.editComment({ commentId: currentCommentId, commentContent })
    } else message.error(data)
  }
  const replyComment = async () => {
    const { data, success } = await postComment({
      articleId,
      parentCommentId: currentCommentId,
      commentContent: replyCommentContent,
    })
    if(success){
        setIsShowReply(false)
        setReplyCommentContent('')
        message.success('回复成功')
        props.replyComment({...data,commentContent:replyCommentContent},commentData.author)
        PubSub.publish('setCommentNums','add')
        PubSub.publish('setCommentNums_phone','add')
    }else message.error(data)
  }
  const actions = [
    action ? (
      <span style={{ color: '#1DA57A' }} key={commentData.commentId + '-1'} onClick={cancelLike}>
        {createElement(LikeFilled)}
        <span key={commentData.commentId + '-2'} className="comment-action">
          {' ' + likes}
        </span>
      </span>
    ) : (
      <span key={commentData.commentId + '-3'} onClick={like}>
        {createElement(LikeOutlined)}
        <span key={commentData.commentId + '-4'} className="comment-action">
          {' ' + likes}
        </span>
      </span>
    ),
    commentData.owner ? (
      <>
        <span onClick={() => {
            setIsShowEdit(!isShowEdit)
        }} key={commentData.commentId + '-6'}>
          修改
        </span>
        <span onClick={() => delComment()} key={commentData.commentId + '-5'}>
          删除
        </span>
      </>
    ) : (
      <span onClick={() => setIsShowReply(!isShowReply)} key={commentData.commentId + '-7'}>
        回复
      </span>
    ),
  ]
  return (
    <div>
      <Comment
        actions={actions}
        author={
            <>
            {
                props.parentUser
                ? <span>
                    <span 
                        className='author_link' 
                        onClick={() => navigate(`/usercollect/${commentData.author.userId}`)} 
                        style={{ color: '#1DA57A' }}>
                        {commentData.author.nickName}
                    </span>
                        {' @ '}
                    <span 
                        className='author_link' 
                        onClick={() => navigate(`/usercollect/${props.parentUser.userId}`)} 
                        style={{ color: '#1DA57A' }}>
                        {props.parentUser.nickName}
                    </span>
                </span>
                : <span 
                    className='author_link' 
                    onClick={() => navigate(`/usercollect/${commentData.author.userId}`)} 
                    style={{ color: '#1DA57A' }}>
                    {commentData.author.nickName}
                </span>
            }
          </>
        }
        avatar={<Avatar onClick={() => navigate(`/usercollect/${commentData.author.userId}`)} src={commentData.author.headImgUrl} />}
        content={<p>{commentData.commentContent}</p>}
        datetime={<span>{commentData.createTime}</span>}
      />
      {isShowEdit && (
        <div>
          <TextArea
          ref={editInputRef}
            onChange={(e) => {
              setCommentContent(e.target.value)
            }}
            value={commentContent}
            rows={2}
          />
          <Button onClick={() => editComment()} style={{ marginTop: 4 }} type="primary">
            提交
          </Button>
        </div>
      )}
      {isShowReply && (
        <div>
          <TextArea
          ref={replyInputRef}
            placeholder={`@ ${commentData.author.nickName}`}
            onChange={(e) => {
              setReplyCommentContent(e.target.value)
            }}
            value={replyCommentContent}
            rows={2}
          />
          <Button onClick={() => replyComment()} style={{ marginTop: 4 }} type="primary">
            评论
          </Button>
        </div>
      )}
      {
        commentData.children.length 
        ? <div className={props.parentUser===undefined ? "child_comment_box" : ''}>
            <CommentList parentUser={commentData.author} childCommentList={commentData.children}/>
            </div>
        : null
      }
    </div>
  )
}
export default App
