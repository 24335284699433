import React from 'react'

import MyInfo from '../../../../components/MyInfo'

import './style.less'

export default function index() {
  return (
    <div className='active_info_left_fixed'>
      <MyInfo/>
    </div>
  )
}
