import { Avatar, Button, List, Skeleton,message} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getFollowers , getFollows} from '../../../../../service/user';
import {getAdvertUser} from '../../../../../service/credit'

import { CreditEnum } from '../../../../PersonalCenter/cpns/TodoList/config/constant';

import Follow from '../../../../../components/Follow'

const App = (props) => {
  const size=10
  const navigate=useNavigate()
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isNullFollowList, setIsNullFollowList] = useState(false)
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const currentRef=useRef(1)
  const type=props.type,id=props.id
  const getFollowByType=async (type,current,size=10)=>{
    setIsNullFollowList(false)
    let _data,_success;
    switch(type){
        case 'follow':
            const {data:dataBFS,success:successBFS}=await getFollowers({userId:id,current,size})
            _data=dataBFS
            _success=successBFS
            break
        case 'concern':
            const {data:dataBFC,success:successBFC}=await getFollows({userId:id,current,size})
            _data=dataBFC
            _success=successBFC
            break
        case CreditEnum.FOLLOW.FOLLOW:
            const {data:dataBADV,success:successBADV}=await getAdvertUser({current,size})
            _data=dataBADV
            _success=successBADV
            break
        default:
            break
    }
    return {_data,_success}
  }
  useEffect(() => {
    ;(async()=>{
        currentRef.current=1
        const {_data,_success}=await getFollowByType(type,currentRef.current)
        if(_success){
            if (_data.pages === _data.current || _data.pages === 0) {
                setIsNullFollowList(true)
            }
            setInitLoading(false);
            setData(_data.records);
            setList(_data.records);
        }else{
            message.error(data)
        }
    })()
  }, []);//eslint-disable-line
  const onLoadMore = () => {
    currentRef.current++
    setLoading(true);
    setList(
      data.concat(
        [...new Array(size)].map(() => ({
          loading: true,
          name: {},
          picture: {},
          key:Math.random()
        })),
      ),
    );
    (async()=>{
        const {_data,_success}=await getFollowByType(type,currentRef.current)
        if(_success){
            if (_data.pages === _data.current || _data.pages === 0) {
                setIsNullFollowList(true)
            }
            const newData = data.concat(_data.records);
            setData(newData);
            setList(newData);
            setLoading(false);
            window.dispatchEvent(new Event('resize'));
        }else{
            message.error(_data)
        }
    })()
  };
  const loadMore =
    !initLoading && !loading ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button disabled={isNullFollowList} onClick={onLoadMore}>加载更多</Button>
      </div>
    ) : null;
  return (
    <List
      className="demo-loadmore-list"
      loading={initLoading}
      itemLayout="horizontal"
      loadMore={loadMore}
      dataSource={list}
      renderItem={(item) => (
        <List.Item>
          <Skeleton avatar title={false} loading={item.loading} active>
            <List.Item.Meta
              avatar={<Avatar src={item.headImgUrl} />}
              // eslint-disable-next-line 
              title={<><a onClick={()=>navigate(`/usercollect/${item.userId}`)}>{item.nickName}</a>&nbsp;&nbsp; <Follow data={item} isAdvert={type===CreditEnum.FOLLOW.FOLLOW}/></>}
              description="个人介绍"
            />
          </Skeleton>
        </List.Item>
      )}
    />
  );
};
export default App;