import { Tabs } from 'antd'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet ,useNavigate,useLocation} from 'react-router-dom'

import Back from '../../components/Back'

import './style.less'

export default function User() {
  const userData = useSelector((state) => state.user)
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    if (!userData?.nickName) {
      navigate('/login',{replace:true})
    }
  }, []) // eslint-disable-line
  const tabsMap=[
    {
    key:'/user/info',
    label:'个人中心'
    },
    {
    key:'/user/edit',
    label:'编辑信息'
    },
    {
    key:'/user/setpassword',
    label:'修改密码'
    }
]
  return (
    <>
    <Back/>
    <div className='user_box'>
      <Tabs
        defaultActiveKey="/user/info"
        activeKey={location.pathname}
        tabPosition="top"
        size="small"
        onTabClick={(k)=>navigate(k,{replace:true})}
        style={{
          height: 220,
        }}
        items={tabsMap}
      />
      <div className="route_box">
      <Outlet/>
      </div>
    </div>
    </>
  )
}
