import React from 'react'

import { PropertySafetyTwoTone } from '@ant-design/icons'
import { Button, Dropdown, Menu ,message} from 'antd';
import './style.less'

import {postSendCredit} from '../../../../service/credit'



export default function index(props) {
    const {author}=props
    const postSendCreditFun=async (quantity)=>{
        const {data,success} = await postSendCredit({userId:author.userId,quantity})
        if(success){
            message.success(data)
        }else message.error(data)
    }
    const menu = (
        <Menu
          items={[
            {
              key: 'give-one-credits',
              label: (
                 // eslint-disable-next-line
                <a onClick={()=>postSendCreditFun(1)}>
                  赠送1币
                </a>
              ),
            },
            {
              key: 'give-two-credits',
              label: (
                 // eslint-disable-next-line
                <a onClick={()=>postSendCreditFun(2)}>
                  赠送2币
                </a>
              ),
            }
          ]}
        />
      );
    
  return (
    <div className="give_credit_box">
        <Dropdown overlay={menu} placement="topRight">
          <Button type="primary" shape="circle" icon={<PropertySafetyTwoTone twoToneColor="#1DA57A" />} />
       </Dropdown>
      </div>
  )
}
