import { Navigate } from 'react-router-dom'

import Home from '../views/Home'
import Main from '../views/Login/Main'
import User from '../views/User'
import UserInfo from '../views/User/Info'
import SetPassword from '../views/User/SetPassword'
import EditUser from '../views/User/EditUserInfo'
import ArticleInfo from '../views/ArticleInfo/index'
import EditArticle from '..//views/EditArticle'
import UserCollect from '../views/User/UserCollect'
import PersonalCenter from '../views/PersonalCenter'
import CreditCenter from '../views/CreditCenter'
import CreditOptionList from '../views/CreditCenter/cpns/OptionList'
import CreditBuy from '../views/CreditCenter/cpns/Buy'
import CreditHistory from '../views/CreditCenter/cpns/CreditHisrory'

const routes =[
    {
        path:'/home/:type/:id',
        element:<Home/>
    },
    {
        path:'/login',
        element:<Main/>
    },
    {
        path:'/article/info/:id',
        element:<ArticleInfo/>
    },
    {
        path:'/article/edit',
        element:<EditArticle/>
    },
    {
        path:'/usercollect/:id',
        element:<UserCollect/>
    },
    {
        path:'/personal/center',
        element:<PersonalCenter/>
    },
    {
        path:'/credit/center',
        element:<CreditCenter/>,
        children:[
            {
                path:'optionlist',
                element:<CreditOptionList/>
            },
            {
                path:'history',
                element:<CreditHistory/>
            },
            {
                path:'buy',
                element:<CreditBuy/>
            }
        ],
    },
    {
        path:'/user',
        element:<User/>,
        children:[
            {
                path:'info',
                element:<UserInfo/>
            },
            {
                path:'setpassword',
                element:<SetPassword/>
            },
            {
                path:'edit',
                element:<EditUser/>
            }
        ]
    },
    {
        path:'/',
        element:<Navigate to='/home/default/default'/>
    }
]

export default routes