import myAxios from '../request/http'

const Article = {
  getCateGorys: '/category/get',
  getArticleAll: '/article/getPage',
  getArticleStar:'/article/star',
  getUnArticleStar:'/article/unStar',
  getArticleTop:'/article/getTop',
  getArticleByCate:'/article/getByCategoryId',
  getArticleBySearch:'/article/fuzzySearch',
  getArticleDetail:'/article/detail',
  getUserDataByArticle:'/article/getData',
  postAddCategory:'/category/add',
  postAddActicle:'/article/add',
  getArticleAllByUser:'/article/get',
  postArticleEdit:'/article/edit',
  getArticleDel:'/article/del',
  getArticleByStar:'/article/getStars',
  getArticleByView:'/article/getHistory',
  getArticleByAdvert:'/article/getTop'
}

export function getArticleByAdvert(data){
  return myAxios({
    url:Article.getArticleByAdvert,
    isToken:true,
    method:'get',
    data
  })
}

export function getCateGorys() {
  return myAxios({
    url: Article.getCateGorys,
    isToken:false,
    method: 'get',
  })
}

export function getArticleAll(data) {
  return myAxios({
    url: Article.getArticleAll,
    method: 'get',
    data,
    isToken:true
  })
}

export function getArticleStar(data){
    return myAxios({
        url:Article.getArticleStar,
        method:'get',
        isToken:true,
        data
    })
}

export function getUnArticleStar(data){
    return myAxios({
        url:Article.getUnArticleStar,
        method:'get',
        isToken:true,
        data
    })
}

export function getArticleTop(data){
    return myAxios({
        url:Article.getArticleTop,
        method:'get',
        isToken:false,
        data
    })
}

export function getArticleByCate(data){
    return myAxios({
        url:Article.getArticleByCate,
        method:'get',
        isToken:true,
        data
    })
}

export function getArticleBySearch(data){
    return myAxios({
        url:Article.getArticleBySearch,
        method:'get',
        isToken:true,
        data
    })
}

export function getArticleDetail(data){
  return myAxios({
    url:Article.getArticleDetail,
    method:'get',
    isToken:true,
    data
  })
}

export function getUserDataByArticle(data){
  return myAxios({
    url:Article.getUserDataByArticle,
    method:'get',
    isToken:false,
    data
  })
}

export function postAddCategory(data){
  return myAxios({
    url:Article.postAddCategory,
    method:'post',
    isToken:true,
    data
  })
}

export function postAddActicle(data){
  return myAxios({
    url:Article.postAddActicle,
    method:'post',
    isToken:true,
    data
  })
}

export function getArticleAllByUser(data){
  return myAxios({
    url:Article.getArticleAllByUser,
    method:'get',
    isToken:true,
    data
  })
}

export function postArticleEdit(data){
  return myAxios({
    url:Article.postArticleEdit,
    method:'post',
    isToken:true,
    data
  })
}

export function getArticleDel(data){
  return myAxios({
    url:Article.getArticleDel,
    method:'get',
    isToken:true,
    data
  })
}

export function getArticleByStar(data){
  return myAxios({
    url:Article.getArticleByStar,
    method:'get',
    isToken:true,
    data
  })
}

export function getArticleByView(data){
  return myAxios({
    url:Article.getArticleAllByUser,
    method:'get',
    isToken:true,
    data
  })
}