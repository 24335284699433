import { messageRight } from './messageRight'
import {message} from 'antd'

import { postAddCredit } from '../service/credit'
import {creditLastTimeByType} from './creditLastTime'

export const postAddCreditFun= async(creditType,nums,_message,creditName,subNum=1)=>{
    const {data,success}=await postAddCredit({creditType})
    if(success) {
        messageRight('账户消息',`${_message}${nums}积分`)
        return true
    }else{
        creditLastTimeByType(creditName,subNum,true)
        message.info(data)
        return false
    }
}