//创建标题节点
class CreateCataMap{
    constructor(key,title,hType,children){
        this.key = key
        this.title = title
        this.hType = hType
        this.children = children
    }
}
//返回数据
class ResponseData{
    data={
        cataList:[],
        domStr:''
    }
    constructor(success,msg,parData){
        this.success = success
        this.msg = msg
        this.data.cataList= parData.cataList
        this.data.domStr=parData.domStr
    }
}
function searchParent(cataList,cataItemMap,insertIndex){
    let tmpInsertIndex=0
    let tmpCataList=cataList
    while(tmpCataList && tmpInsertIndex <= insertIndex){
        if(tmpCataList.length > 1){
            tmpCataList=[tmpCataList[tmpCataList.length-1]]
        }else if(tmpCataList.length===1){
            tmpCataList=tmpCataList[0].children
            tmpInsertIndex++
        }else{
            return new ResponseData(false, `${cataItemMap.hType}-${cataItemMap.label}位置有错,没有遵守标题的顺序/包含关系.`,{cataList,domStr:''})
        }
    }
    tmpCataList.push(cataItemMap)
    return new ResponseData(true,'搜索成功',{cataList,domStr:''})
}
export function htmlToHNode(data){
    let hMap={
        'H1': 'heading-1-1',
        'H2': 'heading-2-1',
        'H3': 'heading-3-1',
        'H4': 'heading-4-1',
        'H5': 'heading-5-1',
    }
    let objElement=document.createElement('div')
    objElement.innerHTML=data
    let docChildren=objElement.childNodes
    let count = 0
    //平铺后的目录数据
    let strList = []
    let cataList = []
    let isHaveError=false
    let dataError={
        success:true,
        msg:'操作成功',
        data:{
            cataList:[],
            domStr:''
        }
    }
    // debugger
    Array.from(docChildren).forEach(item=>{
        if(hMap[item.nodeName.split('-')[0]]){
            strList=String(hMap[item.nodeName]).split('-')
            //递增标签值
            count=Number(strList[2])+1;
            (item).setAttribute('id',hMap[item.nodeName])
            let hNum=Number(item.nodeName.split('H')[1])
            if(hNum===1){
                const cataItem1Map=new CreateCataMap(hMap[item.nodeName],item.innerText,item.nodeName,[])
                cataList.push(cataItem1Map)
            }else{
                const cataItemOtherMap=new CreateCataMap(hMap[item.nodeName],(item).innerText,item.nodeName,[])
                const data= searchParent(cataList,cataItemOtherMap,hNum-2)
                if(!data.success){
                    isHaveError=true
                    dataError= data
                }
            }
            //更新map里的标签值,方便下次使用
            hMap[item.nodeName]=strList[0] + '-' + strList[1] + '-' + count
        }
        
    })
    //至少有一处错误,则返回报错对象
    if(isHaveError){
        return dataError
    }else{
        //返回正常的对象
        return new ResponseData(true, '操作成功',{cataList,domStr:domToStr(objElement)})
    }
}
function domToStr(node){
     return node.innerHTML
}