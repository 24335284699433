import {SET_CATEGORY} from '../constant'

const initCategory=[]

export default function categrorReducer (preState=initCategory,action){
    const {type,data}=action
    switch(type){
        case SET_CATEGORY:
            return data
        default:
            return preState
    }
}