import React, { useEffect, useState } from 'react'
import { useNavigate ,useNavigationType} from 'react-router-dom'
import {useSelector,useDispatch} from 'react-redux'
import { setUserAction } from '../../../redux/actions/user_action'

import { Tabs ,message} from 'antd'

import Back from '../../../components/Back'


import './style.less'

import Login from '../Login'
import Register from '../Register'

import {postRegister,postLogin} from '../../../service/login'
import { getUserData } from '../../../service/user'

export default function Main() {
    const navigate=useNavigate()
    const navigateType=useNavigationType()
    const [toggleID,setToggleID] = useState('001')
    const useData=useSelector(state=>state.user)
    const dispatch = useDispatch()
    const back=()=>{
        if(navigateType === 'POP') navigate('/')
        else navigate(-1)
    }
    const getUserDataFun = async () => {
        try {
          const { data, success } = await getUserData()
          if (success) {
            dispatch(setUserAction(data))
            back()
          }
        } catch (e) {}
      }
    const loginFun=async(values)=>{
        const {data,success} = await postLogin(values)
        if(success){
            message.success('登录成功!')
            localStorage.setItem('token',data)
            getUserDataFun()
        }else{
            message.error(data)
            return 1
        }
    }
    const registerFun= async(values)=>{
        const {data,success} = await postRegister(values)
        if(success){
            message.success('注册成功,请切换登录!')
            values.remember ? 
                localStorage.setItem('name&passowrd',JSON.stringify(values)) 
                : (localStorage.getItem('name&passowrd') && localStorage.removeItem('name&passowrd'))
        }else{
            message.error(data)
        }
    }
    useEffect(()=>{
        if(useData?.nickName) return navigate('/')
    },[])// eslint-disable-line
    const components=[
        {
            id:'001',
            label:'登录',
            children:<Login id='001' toggleId={toggleID} loginFun={loginFun}/>
        },
        {
            id:'002',
            label:'注册',
            children:<Register id='002' toggleId={toggleID} registerFun={registerFun}/>
        }
    ]

  return (
    <div className="login_box">
        <Back/>
      <Tabs
        defaultActiveKey="1"
        centered
        onTabClick={(key)=>setToggleID(key)}
        items={
            components.map(item=>{
                return {
                    label:item.label,
                    key:item.id,
                    children:item.children
                }
            })
        }
      />
    </div>
  )
}
