import { Input ,Button,message} from 'antd';
import React, {  useState } from 'react';
import { useParams } from 'react-router-dom';
import PubSub from 'pubsub-js'

import './style.less'

import {postComment} from '../../../../../../service/comment'

const { TextArea } = Input;
const App = () => {
    const [commentContent,setCommentContent]=useState('')
    const {id:articleId}=useParams()
    const submit=async()=>{
        if(commentContent.trim().length<1) return message.error('评论内容不能为空')
        const {data,success}=await postComment({articleId,commentContent})
        if(success){
            message.success('评论成功')
            setCommentContent('')
            PubSub.publish('addFirstComment',{commentContent,data})
            PubSub.publish('setCommentNums','add')
            PubSub.publish('setCommentNums_phone','add')
            PubSub.publish('unShowComent',true)
        }else{
            message.error(data)
        }
    }
    return (
        <>
          <TextArea 
          showCount={true} 
          rows={2} 
          onChange={(e)=>{setCommentContent(e.target.value)}}
          value={commentContent} />
          <div className="active">
            <Button type="primary" onClick={()=>submit()}>评论</Button> &nbsp;&nbsp;
            <Button danger onClick={()=>setCommentContent('')}>清空</Button>
          </div>
        </>
      );
}

export default App;