import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { UserOutlined, LikeOutlined,EditOutlined,ReadOutlined,ClockCircleOutlined, EyeOutlined, MessageOutlined ,ShoppingCartOutlined} from '@ant-design/icons'
import { Avatar,Card, Tag, Badge, message } from 'antd'

import { splitContentView } from './utils'
import { formatUtcString } from '../../utils/date'
import { IconText } from '../../utils/IconText'
import { postAddCreditFun } from '../../utils/postAddCredit'

import { getArticleStar, getUnArticleStar } from '../../service/article'

import { CreditEnum } from '../../views/PersonalCenter/cpns/TodoList/config/constant'
import { creditLastTimeByType } from '../../utils/creditLastTime'

import PubSub from 'pubsub-js'

import './style.less'

const { Meta } = Card

const App = (props) => {
  const navigate = useNavigate()
  const { item ,isEdit,isAdvert,isBuyAdvert} = props
  const [isStar, setIsStar] = useState(item.liked)
  const starNumRef = useRef(item.articleStar)
  const lastCreditTodoListMap=JSON.parse(localStorage.getItem('lastCreditTodoListMap'))
  //点赞
  const getArticleStarFun = async (articleId) => {
    if (isStar) {
      const { data, success } = await getUnArticleStar({ articleId })
      if (success) {
        message.info(data)
        setIsStar(false)
        starNumRef.current--
      }
    } else {
      const { data, success } = await getArticleStar({ articleId })
      if (success) {
        if(isAdvert){
          if(lastCreditTodoListMap[CreditEnum.STAR_ARTICLE.STAR_ARTICLE].nums>=2) 
           message.info('今日点赞任务已经完成,请明天再来')
          else{
            postAddCreditFun(
              CreditEnum.STAR_ARTICLE.creditType,
              CreditEnum.STAR_ARTICLE.quantity,
              CreditEnum.STAR_ARTICLE.message,
              CreditEnum.STAR_ARTICLE.STAR_ARTICLE
              )
            creditLastTimeByType(CreditEnum.STAR_ARTICLE.STAR_ARTICLE)
        }
        }
        message.success(data)
        setIsStar(true)
        starNumRef.current++
      }
    }
  }
  return (
    <div className="article_card_box">
    <Badge offset={[-10, 50]} style={{
      backgroundColor: '#f0f0f0',
      color:'#1DA57A'
    }} count={isAdvert ? '推广' : ''}>
    <Badge.Ribbon text={item.category.categoryName}>
      <Card
      style={{width:'100%'}}
        onClick={() => {
          isAdvert
          ? navigate(`/article/info/${item.articleId}?advert=true`)
          : navigate(`/article/info/${item.articleId}`)
        }}
        hoverable
        cover={
          <ul className="article_orther_info">
            <li>
              <Avatar
                size={{
                  xs: 30,
                  sm: 30,
                  md: 30,
                  lg: 30,
                  xl: 30,
                  xxl: 40,
                }}
                src={item.author.headImgUrl}
              />
            </li>
            <li>
              <Tag icon={<UserOutlined />}>{item.author.nickName}</Tag>
            </li>
            <li>
              <Tag icon={<ClockCircleOutlined/>}>{formatUtcString(item.createTime)}</Tag>
            </li>
          </ul>
        }
        actions={[
          <span><IconText clickFun={()=>getArticleStarFun(item.articleId)} isStar={isStar} icon={LikeOutlined} text={starNumRef.current} key="list-vertical-star-o" /></span>,
          <IconText icon={MessageOutlined} text={item.articleComments} key="list-vertical-like-o" />,
          <IconText icon={EyeOutlined} text={item.articleViews} key="list-vertical-message" />,
          isBuyAdvert
          ? (<IconText clickFun={()=>PubSub.publish('buyAdvertt',item)} icon= {ShoppingCartOutlined} text={'推广'} key="list-vertical-buy" />)
          :(isEdit
            ? <IconText clickFun={()=>navigate(`/article/edit?articleId=${item.articleId}&categoryId=${item.categoryId}`)} icon={EditOutlined} text={'编辑'} key="list-vertical-edit" />
            : <IconText clickFun={()=>navigate(`/article/info/${item.articleId}`)} icon={ReadOutlined} text={'阅读'} key="list-vertical-message" />)
          
        ]}
      >
        <Meta
        style={{width:'100%',overflow: 'hidden'}}
          avatar={
            <Avatar
              shape="square"
              size={{
                xs: 50,
                sm: 50,
                md: 55,
                lg: 64,
                xl: 80,
                xxl: 100,
              }}
              alt=""
              src={item.coverImg}
            />
          }
          title={item.articleTitle}
          description={splitContentView(item.contentView)}
        />
      </Card>
    </Badge.Ribbon>
    </Badge>
    </div>
  )
}

export default App
