import { Input ,message} from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import PubSub from 'pubsub-js'

const { Search } = Input

const App = (props) => {
  const navigate=useNavigate()
  const onSearch = (value) => {
    if(value.trim().length<1)return message.info('请输入正确的搜索关键字')
    PubSub.publish('closePhoneSearch',true)
    navigate(`/home/search/${value}`)
  }
  return <Search placeholder="搜索文章" onSearch={onSearch} enterButton />
}

export default App
