/**
 * 
 * @param {*} type 任务类型
 * @returns 返回该任务类型在今天是否已经完成过了
 */
export function creditLastTimeByType(type,subNum=1,isAutoDone=false){
    const time=(new Date()).toDateString()+localStorage.getItem('token')
    const credit_time_last=localStorage.getItem('credit_time_last')
    let lastCreditTodoListMap=JSON.parse(localStorage.getItem('lastCreditTodoListMap'))
    //如果当前环境没有缓存就创建新缓存,如果上次保存时间和新时间不一致就创建新缓存
    if(!lastCreditTodoListMap || credit_time_last!==time){
        const map={
            'SIGN_IN':{
                status:'nodo',
                nums:0
            },
            'BROWSE':{
                status:'nodo',
                nums:0
            },
            'REVIEW':{
                status:'nodo',
                nums:0
            },
            'STAR_ARTICLE':{
                status:'nodo',
                nums:0
            },
            'FOLLOW':{
                status:'nodo',
                nums:0
            },
            'LOGIN':{
                status:'nodo',
                nums:0
            }
        }
        localStorage.setItem('lastCreditTodoListMap',JSON.stringify(map))
        localStorage.setItem('credit_time_last',time)
    }
    lastCreditTodoListMap=JSON.parse(localStorage.getItem('lastCreditTodoListMap'))
    if(isAutoDone){
        //检测到用户手动更改完成状态了,就再重新自动改回去(已完成状态)
        console.log('作弊')
        lastCreditTodoListMap[type].status='done'
        lastCreditTodoListMap[type].nums=2
        localStorage.setItem('lastCreditTodoListMap',JSON.stringify(lastCreditTodoListMap))
    }else if(lastCreditTodoListMap[type] && lastCreditTodoListMap[type].status==='nodo'){
        lastCreditTodoListMap[type].nums+=subNum
        localStorage.setItem('lastCreditTodoListMap',JSON.stringify(lastCreditTodoListMap))
        return false
    }else if(lastCreditTodoListMap[type].status==='done') return true
    else throw Error('类型错误')
}