import React, {  } from 'react'
import { Outlet } from 'react-router-dom'

import HomeFooter from '../../components/HomeFooter'
import Back from '../../components/Back'


import './style.less'
export default function Home() {
  return (
    <>
    <Back/>
    <div className='credit_center_box'>
      <aside className='aside_left'>
        左
      </aside>
      <main>
        <Outlet/>
      </main>
      <aside className='aside_right'>
        右
      </aside>
    </div>
      <footer className='home_footer'>
        <HomeFooter/>
      </footer>
    </>
  )
}
