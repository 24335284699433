import React, { useState } from 'react';

import { Upload ,Avatar,message} from 'antd';
import ImgCrop from 'antd-img-crop';

import {BASE_URL} from '../../../../../service/request/config'
import {postEditUserInfo} from '../../../../../service/user'

import './style.less'

const App = (props) => {
    const headerObj = { token: 'zzl_' + localStorage.getItem('token') }
    const action = BASE_URL+'/cos/upload'
    const [imgSrc,setImgSrc]=useState(props.data.headImgUrl || 'https://joeschmoe.io/api/v1/random')
    const onChange = ({ fileList: newFileList}) => {
        const newFile=newFileList[newFileList.length-1]
        if(newFile.status==='done'){
            const {data,success}=newFile.response
            if(success){
                const imgUrl = data.fileBaseUrl+data.filePath
                ;(async ()=>{
                    const {data:_data,success:_success}=await postEditUserInfo({headImgUrl:imgUrl})
                    if(_success){
                        setImgSrc(imgUrl)
                        message.success('上传成功')
                    }else{
                        message.error(_data)
                    }
                })()
            }else{
                message.error(data)
            }

        }
    };
  
  return (
    <div className="avatar_box">
        <ImgCrop modalOk="确认" modalCancel="取消" rotate>
        <Upload
            action={action}
            onChange={onChange}
            headers={headerObj}
        >
        <Avatar size={64} src={imgSrc} />
        </Upload>
        </ImgCrop>

    </div>
  );
};
export default App;