import {createStore , applyMiddleware,combineReducers} from 'redux'

import userReducer from './reducers/user_reducer'
import categrorReducer from './reducers/categrorReducer'

import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'

const allReducer = combineReducers({
    user:userReducer,
    categoryData:categrorReducer
})

const store = createStore(allReducer,composeWithDevTools(applyMiddleware(thunk)))

export default store