import React,{useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import { HomeOutlined,EditOutlined,SearchOutlined, UserOutlined } from '@ant-design/icons'
import { Drawer} from 'antd';

import PubSub from 'pubsub-js'

import { IconText } from '../../utils/IconText'
import Search from '../../components/Nav/cpns/Search'

import './style.less'


const PhoneSearch = () => {
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  useEffect(()=>{
    const showPhoneSearchTimer=PubSub.subscribe('showPhoneSearch',(msg,flag)=>{
      setOpen(true);
    })
    const closePhoneSearchTimer=PubSub.subscribe('closePhoneSearch',(msg,flag)=>{
      onClose(false);
    })
    return ()=>{
      PubSub.unsubscribe(showPhoneSearchTimer)
      PubSub.unsubscribe(closePhoneSearchTimer)
    }
  },[])
  return (
    <>
      <Drawer
        title="搜索"
        placement='top'
        closable={false}
        onClose={onClose}
        open={open}
        key='phone_search'
      >
        <Search/>
      </Drawer>
    </>
  );
};

export default function Footer() {
  const navigate=useNavigate()
  return (
    <>
    <div className='home_footer_box'>
      <div onClick={()=>navigate('/')} className="footer_item">
        <IconText isStopPropagrtion={false} icon={HomeOutlined} key="list-vertical-home-o" />
        <span>首页</span>
      </div>
      <div onClick={()=>PubSub.publish('showPhoneSearch',true)} className="footer_item">
        <IconText isStopPropagrtion={false} icon={SearchOutlined} key="list-vertical-search-o" />
        <span>搜索</span>
      </div>
      <div onClick={()=>navigate('/article/edit')} className="footer_item">
        <IconText isStopPropagrtion={false} icon={EditOutlined} key="list-vertical-create" />
        <span>创作</span>
      </div>
      <div onClick={()=>navigate('/personal/center')} className="footer_item">
        <IconText isStopPropagrtion={false} icon={UserOutlined}  key="list-vertical-user" />  
        <span>我的</span>
      </div>
    </div>
    <PhoneSearch/>
    </>
  )
}
