import { Avatar, Image ,message} from 'antd'
import React,{useEffect} from 'react'
import { useSelector ,useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import { setUserAction } from '../../../../redux/actions/user_action'

import { Dropdown, Menu, Space } from 'antd'

import { getUserData } from '../../../../service/user'

import {creditLastTimeByType} from '../../../../utils/creditLastTime'
import { CreditEnum } from '../../../../views/PersonalCenter/cpns/TodoList/config/constant'
import { postAddCreditFun } from '../../../../utils/postAddCredit'

const App = () => {
  const userData = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const navigate=useNavigate()
  const loginOut=()=>{
    localStorage.removeItem('token')
    dispatch(setUserAction({}))
    message.success('已经退出登录')
  }
  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            userData?.nickName || '小享'
          ),
        },
        {
          type: 'divider',
        },
        {
          key: '2',
          label: (
            <span onClick={()=>{
              navigate('/personal/center')
            }}>
              个人中心
            </span>
          ),
        },
        {
          key: '3',
          label: (
            <span onClick={()=>navigate(`/home/user/${userData.userId}`)}>
              文章管理
            </span>
          ),
        },
        {
          key: '4',
          danger: true,
          label: (
            <span onClick={loginOut}>退出登录</span>
          ),
        },
      ]}
    />
  )
  useEffect(() => {
    const getUserDataFun = async () => {
      try {
        const { data, success } = await getUserData()
        if (success) {
          dispatch(setUserAction(data))
        }
      } catch (e) {}
    }
    const _postAddCreditFun=async()=>{
      if(creditLastTimeByType(CreditEnum.LOGIN.LOGIN,2)) return
      postAddCreditFun(
        CreditEnum.LOGIN.creditType,
        CreditEnum.LOGIN.quantity,
        CreditEnum.LOGIN.message,
        CreditEnum.LOGIN.LOGIN
        )
    }
    localStorage.getItem('token') && getUserDataFun() && _postAddCreditFun()
  }, []) // eslint-disable-line

  return (
    <>
    {
      !userData?.nickName 
      // eslint-disable-next-line
      ? <a onClick={()=>navigate('/login')}>登录</a> 
      : <Dropdown overlay={menu}>
        <span onClick={(e) => e.preventDefault()}>
          <Space>
            <Avatar
              src={
                <Image
                  src={userData.headImgUrl || "https://joeschmoe.io/api/v1/random"}
                  style={{
                    width: 32,
                  }}
                  preview={false}
                />
              }
            />
          </Space>
        </span>
      </Dropdown>
    }
    </>
  )
}

export default App
