import { SET_USER } from "../constant.js"

const initState = {name:'wxl'}
export default function userReducer(preState = initState , action){
    const {type,data} = action
    switch(type){
        case SET_USER:
            return data
        default:
            return preState
    }
}