import React,{useState,useRef, useEffect} from 'react'
import { useNavigate ,useLocation,useSearchParams} from 'react-router-dom'

import {Badge} from 'antd'
import { 
    LikeOutlined, 
    UnorderedListOutlined, 
    MessageOutlined ,
    RollbackOutlined
    } from '@ant-design/icons'
  import { message,Drawer,Input} from 'antd'

import './style.less'

import { getArticleStar,getUnArticleStar } from '../../../../service/article'

import PubSub from 'pubsub-js'

import CategoryCard from '../CategoryCard'
import CommentFirst from '../Comment/cpns/ComentFirst'

import { CreditEnum } from '../../../PersonalCenter/cpns/TodoList/config/constant'
import { postAddCreditFun } from '../../../../utils/postAddCredit'


const CategoryDrawer = (props) => {
  const [open, setOpen] = useState(false);
  const onClose = () => {
      setOpen(false);
    };
    useEffect(()=>{
        const showCategoryTimer=PubSub.subscribe('showCategory',(msg,flag)=>{
            setOpen(true)
        })
        return ()=>{
            PubSub.unsubscribe(showCategoryTimer)
        }
    },[])
  return (
    <>
      <Drawer
        title="目录"
        placement="bottom"
        closable={false}
        onClose={onClose}
        open={open}
        key="article_info_category_drawer"
      >
       <CategoryCard data={props.item.categoryList}/>
      </Drawer>
    </>
  );
};
const CommentDrawer = (props) => {
  const [open, setOpen] = useState(false);
  const onClose = () => {
      setOpen(false);
    };
    useEffect(()=>{
        const showCommentTimer=PubSub.subscribe('showComent',(msg,flag)=>{
            setOpen(true)
        })
        const unShowCommentTimer=PubSub.subscribe('unShowComent',(msg,flag)=>{
          props.showBackGoConent()
          onClose()
        })
        return ()=>{
            PubSub.unsubscribe(showCommentTimer)
            PubSub.unsubscribe(unShowCommentTimer)
        }
    },[])//eslint-disable-line
  return (
    <>
      <Drawer
        title="快来发表评论吧"
        placement="bottom"
        closable={false}
        onClose={onClose}
        open={open}
        key="article_info_category_drawer"
      >
       <CommentFirst/>
      </Drawer>
    </>
  );
};

export default function ArticleInfoFooter(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const [search,]=useSearchParams()
    const isAdvert=search.get('advert')
    const itemRef = useRef(props.item)
    const [isStar, setIsStar] = useState(itemRef.current.liked)
    const [updataFooter,setUpdataFooter]=useState(0)
    const showBackContentRef=useRef(false)
    const backScrollRef=useRef(0)
    const [isShowbackContent,setIsShowBackContent]=useState(showBackContentRef.current)
    const starNumRef = useRef(itemRef.current.articleStar || 0)
    const lastCreditTodoListMap=JSON.parse(localStorage.getItem('lastCreditTodoListMap'))

    useEffect(()=>{
      const setCommentNumsTimer=PubSub.subscribe('setCommentNums_phone',(msg,type)=>{
        switch(type){
          case 'add':
            itemRef.current.articleComments++
            setUpdataFooter(updataFooter+1)
            break
          case 'sub':
            itemRef.current.articleComments--
            setUpdataFooter(updataFooter+1)
            break
          default:
            break
        }
      })
      return ()=>{
        PubSub.unsubscribe(setCommentNumsTimer)
      }
    },[updataFooter])//eslint-disable-line
    const getArticleStarFun = async (articleId) => {
      console.log('type',isStar)
        if (isStar) {
          const { data, success } = await getUnArticleStar({ articleId })
          if (success) {
            message.info(data)
            setIsStar(false)
            starNumRef.current--
          }
        } else {
          const { data, success } = await getArticleStar({ articleId })
          if (success) {
            if(isAdvert ){
              if(
                lastCreditTodoListMap[CreditEnum.STAR_ARTICLE.STAR_ARTICLE].status==='done'
                || lastCreditTodoListMap[CreditEnum.STAR_ARTICLE.STAR_ARTICLE].nums===2
              ) message.info('今日点赞任务已经完成 ')
              else{
              postAddCreditFun(
                CreditEnum.STAR_ARTICLE.creditType,
                CreditEnum.STAR_ARTICLE.quantity,
                CreditEnum.STAR_ARTICLE.message,
                CreditEnum.STAR_ARTICLE.STAR_ARTICLE)
            }
            }
            message.success(data)
            setIsStar(true)
            starNumRef.current++
          }
        }
      }
      const goComment=()=>{
        const id='article_comment'
        let element = document.getElementById(id)
        if(element){
            showBackContentRef.current=true
            setIsShowBackContent(true)
            element.scrollIntoView({ behavior: 'smooth', block: 'center' })
            navigate(`${location.pathname}#${id}`,{replace:true})
        }
      }
      const goContent=()=>{
        showBackContentRef.current=false
        window.scrollTo(0,backScrollRef.current)
        setIsShowBackContent(false)
        navigate(`${location.pathname}`,{replace:true})
      }
      const getScroll=()=>{
        if(showBackContentRef.current) return
        backScrollRef.current=window.scrollY
      }
      useEffect(()=>{
        window.addEventListener('scroll',getScroll)
        getScroll()
        return ()=>window.removeEventListener('scroll',getScroll)
      },[]) //eslint-disable-line
      const showBackGoConent=()=>{
        setIsShowBackContent(true)
        goComment()
      }
  return (
    <>
    <div className='article_info_footer_box'>
            <Badge
            offset={[10, -2]}
            size="small"
           >
            <span onClick={()=>PubSub.publish('showComent',true)}>
            <Input 
            style={{width:150}}
            placeholder="写评论..."
            disabled/>
            </span>
          </Badge>
          {
            isShowbackContent 
            ? <Badge
                offset={[10, -2]}
                size="small">
                <RollbackOutlined onClick={()=>goContent()}/>
              </Badge>
            :<Badge
            onClick={()=>goComment()}
            offset={[10, -2]}
            size="small"
            count={itemRef.current.articleComments>0 ? itemRef.current.articleComments : '评论'}
            overflowCount={999}>
            <MessageOutlined key="active_info_footer_comment" />
            </Badge>
        }
        <Badge
            onClick={()=>getArticleStarFun(itemRef.current.articleId)} 
            offset={[10, -2]}
            size="small"
            count={starNumRef.current}
            overflowCount={999}
          >
            <LikeOutlined style={{color: isStar ? 'red' : ''}} key="active_info_footer_star" />
          </Badge>
            <Badge
            offset={[10, -2]}
            size="small"
           >
            <UnorderedListOutlined onClick={()=>PubSub.publish('showCategory',true)} key="active_info_footer_category" />
          </Badge>
    </div>
    <CategoryDrawer item={itemRef.current}/>
    <CommentDrawer showBackGoConent={()=>showBackGoConent()}/>
    </>
  )
}
