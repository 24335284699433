import myAxios from "../request/http";

const Credit = {
    getCredit:'/credit/get',
    postAddCredit:'/credit/add',
    getCreditHistory:'/credit/history',
    postSendCredit:'/credit/send',
    getExchange:'/credit/exchange',
    getAdvertArticle:'/credit/getAdvertArticle',
    getAdvertUser:'/credit/getAdvertUser',
}

export function getAdvertUser(data){
    return myAxios({
        url:Credit.getAdvertUser,
        method:'get',
        isToken:true,
        data
    })
}

export function getAdvertArticle(data){
    return myAxios({
        url:Credit.getAdvertArticle,
        method:'get',
        isToken:true,
        data
    })
}

export function getExchange(data){
    return myAxios({
        url:Credit.getExchange,
        method:'get',
        isToken:true,
        data
    })
}

export function postSendCredit(data){
    return myAxios({
        url:Credit.postSendCredit,
        method:'post',
        isToken:true,
        data
    })
}

export function getCredit(){
    return myAxios({
        url:Credit.getCredit,
        method:'get',
        isToken:true,
    })
}

export function postAddCredit(data){
    return myAxios({
        url:Credit.postAddCredit,
        method:'post',
        isToken:true,
        data
    })
}

export function getCreditHistory(data){
    return myAxios({
        url:Credit.getCreditHistory,
        method:'get',
        isToken:true,
        data
    })
}