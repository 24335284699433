import { Button,Form, Input } from 'antd';
import React from 'react';

import {message} from 'antd'

import { postSetUserPassword } from '../../../service/user';

const App = () => {
  const onFinish = async(values) => {
    const {data,success}=await postSetUserPassword(values)
    if(success){
      message.success(data)
    }else message.error(data)
  };

  return (
    <Form
    style={{width:'100%'}}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="旧密码"
        name="oldPwd"
        rules={[
          {
            required: true,
            message: '请输入旧密码',
          },
        ]}
      >
        <Input autoComplete='off' type="password" />
      </Form.Item>
      <Form.Item
        label="新密码"
        name="pwd"
        rules={[
          {
            required: true,
            message: '请输入新密码',
          },
        ]}
      >
        <Input autoComplete='off' type="password" />
      </Form.Item>

      <Form.Item
      >
        <div className="user_info_btn">
        <Button block type="primary" htmlType="submit">
          修改
        </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default App;