import { DollarCircleFilled ,ShoppingFilled} from '@ant-design/icons';
import {  List ,Drawer,message} from 'antd';
import React,{useState,forwardRef,useRef,useImperativeHandle,useEffect} from 'react';
import {useSelector} from 'react-redux'
import { IconText } from '../../../../utils/IconText';
import ArticleList from '../../../ArticleList'
import AuthorInfo from '../../../ArticleInfo/cpns/AuthorInfo'

import PubSub  from 'pubsub-js';

import {getExchange,getCredit} from '../../../../service/credit'

import './style.less'

const BuyInfo = forwardRef((props,ref) => {
  const user = useSelector(state=>state.user)
  const [open, setOpen] = useState(false);
  const advertDataRef=useRef({type:'myInfo'})
  const creditNumsRef=useRef(0)
  useImperativeHandle(ref,()=>({
    showDrawer : (data) => {
      console.log('data',data)
      advertDataRef.current=data
      setOpen(true)
    }
  }))
  const onClose = () => {
    setOpen(false);
  };
  useEffect(()=>{
    ;(async()=>{
      const {data,success}=await getCredit()
      if(success){
        creditNumsRef.current=parseInt(data)
        console.log(creditNumsRef.current)
      }else message.error(data)
    })()
  },[user])
  useEffect(()=>{
    const buyAdvertTimer = PubSub.subscribe('buyAdvertt',(msg,_data)=>{
      console.log('dfdfdfd',_data)
      if(creditNumsRef.current<advertDataRef.current.price) return message.error(`积分不足`)
      ;(async()=>{
        const {data,success} = advertDataRef.current.type==='myArticle' 
                              ? await getExchange({type:'1',id:_data.articleId})
                              : await getExchange({type:'2',id:user.userId})
        if(success){
          message.success('兑换成功,正在加热')
          creditNumsRef.current--
          onClose()
        }else message.error(data)
      })()
    })
    return ()=>PubSub.unsubscribe(buyAdvertTimer)
  },[])//eslint-disable-line
  return (
    <>
      <Drawer
        title="选择推广文章"
        placement='bottom'
        closable={false}
        onClose={onClose}
        open={open}
        key='advert-credit-buy'
      >
        {
          advertDataRef.current.type==='myArticle'
          ?<ArticleList type_ID={{type:'userBuyAdvert',id:user?.userId}}/>
          : <AuthorInfo data={user} isAdvert={true} NotShowFollow={true}/>
        }
      </Drawer>
    </>
  );
});

const App = () => {
  const BuyInfoRef = useRef()
  const data = [
    {
      title:'加热文章',
      description:'购买后你的文章将会出现在推广列表里,该推广列表会显示在每个用户的每日任务里',
      price:1,
      type:'myArticle'
    },
    {
      title:'涨粉',
      description:'购买后你的博客信息将会出现在推广列表里,该推广列表会显示在每个用户的每日任务里',
      price:1,
      type:'myInfo'
    }
  ]
  return(
    <div className="advert_credit_box">
      <List
        itemLayout="vertical"
        size="large"
        dataSource={data}
        renderItem={(item) => (
          <List.Item
          onClick={()=>BuyInfoRef.current.showDrawer(item)}
            key={item.title}
            actions={[
              <IconText color="red" icon={DollarCircleFilled} text={`10分钟/${item.price}积分`} key="credit-advert-price" />,
              <span style={{cursor: 'pointer'}}><IconText isStopPropagrtion={false} icon={ShoppingFilled} text="兑换" key="credit-advert-buy" /></span>
            ]}
            extra={
              <img
                width={272}
                alt="logo"
                src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
              />
            }
          >
            <List.Item.Meta
              title={<span>{item.title}</span>}
              description={item.description}
            />
          </List.Item>
        )}
      />
      <BuyInfo ref={BuyInfoRef}/>
    </div>
  );
}
export default App;