import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {useDispatch} from 'react-redux'
import { setUserAction } from '../../../../redux/actions/user_action'

import {Avatar,Button,message} from 'antd'
import { 
    LikeOutlined, 
    EyeOutlined, 
    MessageOutlined 
} from '@ant-design/icons'

import {getUserDataByArticle} from '../../../../service/article'

import Follow from '../../../../components/Follow'

import { IconText } from '../../../../utils/IconText'

import PubSub from 'pubsub-js'

import './style.less'

export default function Author(props) {
    const navigate=useNavigate()
    const {data} = props
    const [authorData,setAuthorData]=useState({})
    const dispatch = useDispatch()
    const loginOut=()=>{
        localStorage.removeItem('token')
        dispatch(setUserAction({}))
        message.success('已经退出登录')
        navigate('/')
    }
    useEffect(()=>{
        ;(async ()=>{
            const {data:authorData,success}=await getUserDataByArticle({userId:data.userId})
            if(success){
                setAuthorData({...authorData})
            }
        })()
    },[data])
  return (
    <div>
        <div className="avatar">
            {
                !props.NotShowFollow //显示关注,即不是查看个人信息
                ?<><span onClick={()=>navigate(`/usercollect/${data.userId}`)}>
                        <Avatar src={data.headImgUrl} />
                        <Button style={{marginLeft:6}} type="link">{data.nickName}</Button>
                 </span>
                <Follow data={data}/></>
                :<span>
                    <span onClick={()=>navigate(`/user/info`)}>
                    <Avatar src={data.headImgUrl} />
                    <Button style={{marginLeft:6}} type="link">{data.nickName+' >'}</Button>
                    </span>
                    {
                        props.isAdvert
                        ? <Button type='primary' onClick={()=>PubSub.publish('buyAdvertt',true)}>推广</Button>
                        :<span onClick={()=>loginOut()}>
                        <Button style={{marginLeft:6,color:'#455A64'}} type="link">{'退出登录'}</Button>
                        </span>
                    }
                </span>
            }
        </div>
        <div className='author_nums'>
            <div className="author_item">
            <IconText icon={LikeOutlined} text={authorData?.starNum || 0} key="stars" />
            <span className='nums'>被点赞数</span>
            </div>
            <div className="author_item">
            <IconText icon={MessageOutlined} text={authorData?.commentNum || 0} key="comments" />
            <span className='nums'>被评论数</span>
            </div>
            <div className="author_item">
            <IconText icon={EyeOutlined} text={authorData?.viewsNum || 0} key="views" />
            <span className='nums'>被浏览数</span>
            </div>
        </div>
    </div>
  )
}
