import React, { useEffect, useRef, useState } from 'react'
import { useParams ,useSearchParams} from 'react-router-dom'

import {message ,Alert}from 'antd'

import Nav from '../../components/Nav';
import ArticleContent from './cpns/ArticleContent';
import ArticleInfoRight from './cpns/ArticleInfoRight';
import ArticleInfoLeft from './cpns/AricleInfoLeft'
import ArticleInfoFooter from './cpns/ArticleInfoFooter';

import {getArticleDetail} from '../../service/article';

import { CreditEnum } from '../PersonalCenter/cpns/TodoList/config/constant';

import { htmlToHNode } from '../../utils/htmlToNode';
import {postAddCreditFun} from '../../utils/postAddCredit'
import { creditLastTimeByType } from '../../utils/creditLastTime';

import './style.less'

export default function ArticleInfo() {
  const {id}=useParams()
  const [search,]=useSearchParams()
  const advert=search.get('advert')
  const firstRenderRef=useRef(false)
  const [articleInfo,setArticleInfo]=useState({})
  const [time,setTime]=useState(0)
  
  const lastCreditTodoListMap=JSON.parse(localStorage.getItem('lastCreditTodoListMap'))
  //计时用户是否阅读了10秒推广文章
  useEffect(()=>{
    if(advert){
      if(
      lastCreditTodoListMap[CreditEnum.BROWSE.BROWSE].nums===2 || 
      lastCreditTodoListMap[CreditEnum.BROWSE.BROWSE].status==='done'
      ) return message.info('今日已完成全部阅读任务,请明天再来')
    }else if(!advert) return
    const readTimer = setInterval(()=>{
      setTime(time+1)
      if(time===10){
        postAddCreditFun(
          CreditEnum.BROWSE.creditType,
          CreditEnum.BROWSE.quantity,
          CreditEnum.BROWSE.message,
          CreditEnum.BROWSE.BROWSE
          )
        creditLastTimeByType(CreditEnum.BROWSE.BROWSE)
        clearInterval(readTimer)
      }
    },1000)
    return ()=>clearInterval(readTimer)
  },[time]) //eslint-disable-line
  useEffect(()=>{
    ;(async()=>{
      if(firstRenderRef.current) return
      firstRenderRef.current=true
      const {data,success}=await getArticleDetail({articleId:id})
      if(success){
        const newContentData=htmlToHNode(data['articleContent']);
        data['articleContent']=newContentData.success ? newContentData.data.domStr : data['articleContent'];
        data['categoryList']=newContentData.success ? newContentData.data.cataList : [];
        setArticleInfo({...data})
      }else{
        message.error(data)
      }
    })()
  },[id])
  return (
    <>
    <Nav/>
     {advert 
     && lastCreditTodoListMap[CreditEnum.BROWSE.BROWSE].status==='nodo' 
     && lastCreditTodoListMap[CreditEnum.BROWSE.BROWSE].nums<2
     && <div className="advert_tips_box">
      {time<=10 && <Alert message={`已经阅读${time}秒`} type="success" />}
    </div>}
    <div className='article_info_box'>
      <aside className='aside_left'>
      <ArticleInfoLeft/>
      </aside>
      <main>
       <ArticleContent articleData={articleInfo}/>
      </main>
      <aside className='aside_right'>
        <ArticleInfoRight articleData={articleInfo}/>
      </aside>
    </div>
    <footer className='article_info_footer'>
        {articleInfo.createTime && <ArticleInfoFooter item={articleInfo}/>}
    </footer>
    </>
  )
}
