import React from 'react'

import Nav from '../../../components/Nav'
import MyInfo from '../../../components/MyInfo'
import UserMain from './cpns/UserMain'
import Back from '../../../components/Back'

import './style.less'

export default function UserCollect() {
   
  return (
    <>
        <Nav/>
        <Back/>
    <div className='user_collect_box'>
      <aside className='aside_left'>
        <MyInfo/>
      </aside>
      <main>
       <UserMain/>
      </main>

      <aside className='aside_right'>
        右
      </aside>
    </div>
    </>
  )
}
