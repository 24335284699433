import { Button, Divider, Modal,List, Skeleton, message,Avatar,Image } from 'antd'
import { FileTwoTone } from '@ant-design/icons';
import React, { forwardRef, useEffect, useRef, useState ,useImperativeHandle} from 'react'

import { getCreditHistory } from '../../../../service/credit'

import './style.less'

const CreditHistoryInfo = forwardRef((props,ref) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data,setData]=useState()
  useImperativeHandle(ref,()=>({
    showModal : (data) => {
        setIsModalOpen(true);
        console.log('dfata',data)
        setData({...data})
      }
  }))
  const handleOk = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Modal okText={'关闭'} cancelText={'有疑问'} closable={false} title="详细记录" open={isModalOpen} onOk={handleOk}>
        <div className="history_info_box">
        {
            data?.creditType==='用户赠送'
            ? <Avatar src={<Image src={data?.source.headImgUrl}/>}  size={40} icon={<FileTwoTone twoToneColor="#1DA57A"/>} />
            : <Avatar style={{'backgroundColor': '#81C784'}} size={40} icon={<FileTwoTone twoToneColor="#1DA57A"/>} />
        }
        <span style={{color:'#1DA57A'}}>
            {data?.creditType!=='用户赠送'
            ? data?.creditType 
            : '赠送' + data?.source.nickName
            }&nbsp;&nbsp; 
            {data?.quantity}积分
        </span>
        </div>
        <Divider />
        <div><span style={{color:'#78909C'}}>时间: </span><span>{data?.createTime}</span></div>
        <div><span style={{color:'#78909C'}}>单号: </span><span>{data?.historyId}</span></div>
      </Modal>
    </>
  );
});

const App = (props) => {
  const size = 10
  const [initLoading, setInitLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isNullFollowList, setIsNullFollowList] = useState(false)
  const [data, setData] = useState([])
  const [list, setList] = useState([])
  const currentRef = useRef(1)
  const CreditHistoryInfoRef=useRef()
  const {creditType,orederBy}=props
  useEffect(() => {
    currentRef.current=1
    ;(async () => {
      const { data, success } = await getCreditHistory({ creditType, current: currentRef.current, size, orederBy })
      if (data.pages === data.current || data.pages === 0) {
        setIsNullFollowList(true)
      }
      if (success) {
        setInitLoading(false);
        setData(data.records);
        setList(data.records);
      } else message.error(data)
    })()
  }, []) //eslint-disable-line
  const onLoadMore = () => {
    currentRef.current++
    setLoading(true)
    setList(
      data.concat(
        [...new Array(size)].map(() => ({
          loading: true,
          name: {},
          picture: {},
          key: Math.random(),
        }))
      )
    )
    ;(async () => {
      const { data:_data, success } = await getCreditHistory({ creditType, current: currentRef.current, size, orederBy })
      if (success) {
        if (_data.pages === _data.current || _data.pages === 0) {
          setIsNullFollowList(true)
        }
        const newData = data.concat(_data.records)
        setData(newData)
        setList(newData)
        setLoading(false)
        window.dispatchEvent(new Event('resize'))
      } else {
        message.error(_data)
      }
    })()
  }
  const loadMore =
    !initLoading && !loading ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button disabled={isNullFollowList} onClick={onLoadMore}>
          加载更多
        </Button>
      </div>
    ) : null
  return (
    <div className="history_credit_box">
        <List
          className="demo-loadmore-list"
          loading={initLoading}
          itemLayout="horizontal"
          loadMore={loadMore}
          dataSource={list}
          renderItem={(item) => (
              <List.Item onClick={()=>CreditHistoryInfoRef.current.showModal(item)}>
                {
                    item.creditType==='用户赠送'
                    ? <Avatar src={<Image src={item.source.headImgUrl}/>}  size={40} icon={<FileTwoTone twoToneColor="#1DA57A"/>} />
                    : <Avatar style={{'backgroundColor': '#81C784'}} size={40} icon={<FileTwoTone twoToneColor="#1DA57A"/>} />
                }
                <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                // eslint-disable-next-line 
                title={<>&nbsp;&nbsp;
                <span style={{color:'#1DA57A'}}>
                    {item.creditType!=='用户赠送'
                    ? item.creditType 
                    : `${parseInt(item.quantity)>0 ? '收到: ' : '赠送: '}` + item.source.nickName
                    }&nbsp;&nbsp; 
                    {item.quantity}积分
                </span>
                </>}
                description={<>&nbsp;&nbsp;{item.createTime}</>}
                />
              </Skeleton>
            </List.Item>
          )}
        />
        <CreditHistoryInfo ref={CreditHistoryInfoRef}/>
    </div>
  )
}
export default App
