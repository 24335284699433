import React from 'react'

import {Card} from 'antd'

export default function index() {
  return (
    <Card
        size="small"
        title='关于'
        style={{
          width: 280,
          marginBottom:12
        }}
      >
        <p></p>
      </Card>
  )
}
