import myAxios from "../request/http";

const User = {
    getUserData : '/user/info',
    postEditUserInfo:'/user/edit',
    postSetUserPassword:'/user/editPwd',
    getFollow:'/user/follow',
    getUnFollow:'/user/unFollow',
    getUserById:'/user/getById',
    getFollowers:'/user/getFollowers',
    getFollows:'/user/getFollows'
}

export function getUserData(){
    return myAxios({
        url:User.getUserData,
        method:'get',
        isToken:true
    })
}

export function postEditUserInfo(data){
    return myAxios({
        url:User.postEditUserInfo,
        method:'post',
        isToken:true,
        data
    })
}

export function postSetUserPassword(data){
    return myAxios({
        url:User.postSetUserPassword,
        method:'post',
        isToken:true,
        data
    })
}

export function getFollow(data){
    return myAxios({
        url:User.getFollow,
        method:'get',
        isToken:true,
        data
    })
}

export function getUnFollow(data){
    return myAxios({
        url:User.getUnFollow,
        method:'get',
        isToken:true,
        data
    })
}

export function getUserById(data){
    return myAxios({
        url:User.getUserById,
        method:'get',
        isToken:false,
        data
    })
}

export function getFollowers(data){
    return myAxios({
        url:User.getFollowers,
        method:'get',
        isToken:true,
        data
    })
}

export function getFollows(data){
    return myAxios({
        url:User.getFollows,
        method:'get',
        isToken:true,
        data
    })
}