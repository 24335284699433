import React, { useEffect } from 'react'
import { useSelector ,useDispatch} from 'react-redux'

import { setUserAction } from '../../../redux/actions/user_action'

import { Button, Form, Input, Radio ,Select ,message} from 'antd'

import { userRules } from '../config/rules'
import { postEditUserInfo } from '../../../service/user'

import './style.less'

const App = () => {
  const userData = useSelector((state) => state.user)
  const dispatch=useDispatch()
  const [form] = Form.useForm()
  const { Option } = Select;
  const validateMessages = { ...userRules }
  useEffect(()=>{
      form.setFieldsValue({
        ...userData,
        sex:userData.sex==='保密' ? '2' : (userData.sex==='男' ? '1' : '0'),
        phoneBefore:userData.phoneNum.length>0 ? userData.phoneNum.slice(0,2) : '86',
        phoneNum:userData.phoneNum.length>2 ? userData.phoneNum.slice(2) : ''
      })
  },[form,userData])

  const onFinish = async (values) => {
    const {data,success} = await postEditUserInfo({...values,phoneNum:(values.phoneBefore.slice(0,2))+values.phoneNum})
    if(success){
        message.success('修改成功')
        dispatch(setUserAction(data))
    }else{
        message.error(data)
    }
  };

  return (
    <Form
    style={{width:'100%'}}
      form={form}
      validateMessages={validateMessages}
      onFinish={onFinish}
    >
      <Form.Item name="userName" label="用户名">
         <Input placeholder="输入用户名" />
      </Form.Item>
      <Form.Item name="nickName" label="昵称">
        <Input placeholder="输入昵称" />
      </Form.Item>
      <Form.Item
        name="phoneNum"
        label="手机号"
      >
        <Input
          addonBefore={
            <Form.Item name="phoneBefore" noStyle>
              <Select
                style={{
                  width: 70,
                }}
              >
                <Option key='86' value="86">+86</Option>
                <Option key='87' value="87">+87</Option>
              </Select>
            </Form.Item>
          }
          style={{
            width: '100%',
          }}
        />

      </Form.Item>
      <Form.Item
        name="email"
        label="邮箱"
        rules={[
          {
            type: 'email',
          },
        ]}
      >
        <Input placeholder="输入邮箱" />
      </Form.Item>
      <Form.Item name="sex" label="性别">
        <Radio.Group>
          <Radio value="1"> 男 </Radio>
          <Radio value="0"> 女 </Radio>
          <Radio value="2"> 保密 </Radio>
        </Radio.Group>
      </Form.Item>
      <div className='user_info_btn'><Button block type="primary" htmlType="submit">修改</Button></div>
    </Form>
  )
}

export default App
