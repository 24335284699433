import React from 'react'
import {  Space } from 'antd'
export const IconText = ({ isStar, clickFun, icon, text ,isStopPropagrtion=true,color=''}) => (
    <Space style={{ color: isStar ? 'red' : color }} onClick={(e) =>{
      isStopPropagrtion && e.stopPropagation()
      clickFun && clickFun()
    }}>
      {React.createElement(icon)}
      {text}
    </Space>
  )