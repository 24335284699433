import React, { useState, memo,useEffect, useRef } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Divider, Modal, Select, Space, Form, Input, Button, message } from 'antd'

import { getCateGorys,postAddCategory} from '../../../../service/article'

const { Option, OptGroup } = Select

const CreateCategory = (props) => {
    const {data,isUseRelusByParent}=props
    console.log('dddd',isUseRelusByParent)
    const formRef=useRef()
  const onFinish = async(values) => {
    const {data,success}=await postAddCategory({...values})
    if(success){
        message.success('创建成功')
        props.finishedCallback(data)
    }else{
        message.error(data)
    }
  }
  useEffect(()=>{
    formRef.current.setFieldsValue({
        parentCategoryId:'',
        categoryName:'',
        categoryAlias:''
      })
  })
  
  return (
    <>
      <Form
        initialValues={{}}
        ref={formRef}
        onFinish={onFinish}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
      >
        <Form.Item
          label="父级分类"
          name="parentCategoryId"
          rules={isUseRelusByParent ? [
            {
              required: {isUseRelusByParent},
              message: '父级分类为必填项',
            },
          ]:null}
        >
          <Select>
            {
                data.map(item=>{
                    return <Select.Option key={item.categoryId} value={item.categoryId}>{item.categoryName}</Select.Option>
                })
            }
          </Select>
        </Form.Item>
        <Form.Item
          label="分类名字"
          rules={[
            {
              required: true,
              message: '分类名字为必填项',
            },
          ]}
          name="categoryName"
        >
          <Input />
        </Form.Item>
        <Form.Item 
          rules={[{
              required: {isUseRelusByParent},
              message: '父级分类为必填项',
            }]}
          label="分类别名" 
          name="categoryAlias">
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

const App = (props) => {
  const [categoryData, setCategoryData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [checkedCategoryId,setCheckedCategoryId]=useState(props.categoryIdByUser)
  
  const getCateGorysFun=async()=>{
      const { data, success } = await getCateGorys()
      if (success) {
        setCategoryData([...data])
        const categoryId=data[0].children[0].categoryId
        if(!props.categoryIdByUser && !isOpen){
          setCheckedCategoryId(categoryId)
          props.checkedCategoryFun(categoryId)
        }
      } else {
        message.error(data)
      }
  }
  useEffect(() => {
    getCateGorysFun()
  }, []) //eslint-disable-line
  const handleCancel = () => {
    setIsOpen(false)
  }

  const clickAdd = () => {
    setIsOpen(true)
  }
  const finishedCallback=(data)=>{
    getCateGorysFun().then(()=>{
      props.checkedCategoryFun(data.categoryId)
      setCheckedCategoryId(data.categoryId)
      setIsOpen(false)
    })
  }
  const selectFun=(value)=>{
    setCheckedCategoryId(value)
    props.checkedCategoryFun(value)
  }
  return (
    <>
      <Select
        style={{
          width: 300,
        }}
        placeholder="选择分类"
        value={checkedCategoryId}
        onSelect={selectFun}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: '8px 0',
              }}
            />
            <Space
              style={{
                padding: '0 8px 4px',
              }}
            >
              <Button type="primary" icon={<PlusOutlined />} onClick={() => clickAdd()}>
                创建分类
              </Button>
            </Space>
          </>
        )}
      >
        {categoryData.map((parent) => {
          return (
            <OptGroup key={parent.categoryId} label={parent.categoryName}>
              {parent.children.map((child) => {
                return (
                  <Option key={child.categoryId} value={child.categoryId}>
                    {child.categoryName}
                  </Option>
                )
              })}
            </OptGroup>
          )
        })}
      </Select>
      <Modal okText={''} cancelText="取消" title="创建分类" open={isOpen} onCancel={handleCancel}>
        <CreateCategory isUseRelusByParent={props.isUseRelusByParent} data={categoryData} finishedCallback={finishedCallback}/>
      </Modal>
    </>
  )
}
export default memo(App)
