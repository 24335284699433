 /* eslint-disable no-template-curly-in-string */
export const userRules = {
    // eslint-disable-next-line
    required: '${label} is required!',
    types: {
      email: '${label} 格式不正确',
      number: '${label} 必须是数字',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  /* eslint-enable no-template-curly-in-string */