import React, {  } from 'react'
import {useSelector} from 'react-redux'

import HomeFooter from '../../components/HomeFooter'
import OptionList from './cpns/OptionList'
import AuthorInfo from '../ArticleInfo/cpns/AuthorInfo'

import './style.less'
export default function PersonalCenter() {
    const user=useSelector(state=>state.user)
  return (
    <>
    <div className='personal_cneter_box'>
      <aside className='aside_left'>
        左
      </aside>
      <main>
        <div className="author_info">
            <AuthorInfo data={user} NotShowFollow={true}/>
        </div>
        
        <OptionList/>
      </main>
      <aside className='aside_right'>
        <AuthorInfo data={user} NotShowFollow={true}/>
      </aside>
    </div>
      <footer className='home_footer'>
        <HomeFooter/>
      </footer>
    </>
  )
}
