import myAxios from "../request/http";

const Login = {
    getCode : '/auth/code',
    postRegister:'/auth/register',
    postLogin:'/auth/login'
}

export function getCode(){
    return myAxios({
        url:Login.getCode,
        method:'get',
    })
}

export function postRegister(data){
    return myAxios({
        url:Login.postRegister,
        method:'post',
        data
    })
}

export function postLogin(data){
    return myAxios({
        url:Login.postLogin,
        method:'post',
        data
    })
}