import React, {  } from 'react'

import { Card } from 'antd'


export default function CategoryCard(props) {
  return (
    <Card
      size="small"
      title={props.title}
      style={{
        width: 280,
        marginBottom:12
      }}
    >
      {<props.content data={props.data}/>}
    </Card>
  )
}
