import React from 'react';
import {useSelector} from 'react-redux'

import {  Table, Tag } from 'antd';

import UploadAvatar from './cpns/UploadAvatar'

import './style.less'

const columns = [
  {
    title: '用户名',
    dataIndex: 'userName',
    key: 'userName',
    width:100,
    render: (text) => <span>{text}</span>,
  },
  {
    title: '昵称',
    width:100,
    dataIndex: 'nickName',
    key: 'nickName',
  },
  {
    title: '手机号',
    width:200,
    dataIndex: 'phoneNum',
    key: 'phoneNum',
  },
  {
    title: '性别',
    width:100,
    key: 'sex',
    dataIndex: 'sex',
    render: (_, { sex }) => (
      <>
        {sex.map((tag) => {
          let color = tag === '男' ? 'geekblue' : 'volcano';

          if (tag === '保密') {
            color = 'green';
          }

          return (
            <Tag color={color} key={tag}>
              {tag}
            </Tag>
          );
        })}
      </>
    ),
  }
];

const App = () => {
    const userData=useSelector(state=>state.user)
    const tableMapByUserData = [{...userData,'sex':[userData.sex]}]
    return (
        <div className='user_info_box'>
          <span className="avatar">
            <UploadAvatar data={userData}/>
          </span>
        <Table 
        scroll={{
          y: 240,
        }}
        rowKey={item=>item.userName}
        columns={columns} 
        dataSource={tableMapByUserData} 
        pagination={false}
        />
        </div>
    )
};

export default App;