import React, { useEffect } from 'react'

import ArticleActive from '../ArticleActive'
import Prism from 'prismjs'

import Comment from '../Comment'
import GiveCredit from '../GiveCredit'

import './style.less'

export default function ArticleContent(props) {
  const { articleData } = props
  useEffect(() => {
    articleData.articleContent && Prism.highlightAll()
  })
  return (
    <div className="article_box">
      <GiveCredit author={articleData?.author}/>
      {articleData.articleId && <ArticleActive articleData={articleData} />}
      <div className="article_content_box" dangerouslySetInnerHTML={{ __html: articleData.articleContent }}></div>
      <Comment />
    </div>
  )
}
