import React, { useEffect, useRef } from 'react'
import { useNavigate ,useLocation} from 'react-router-dom'

import { DownOutlined } from '@ant-design/icons'
import { Tree } from 'antd'

import './style.less'

const CategroyList = (props) => {
    const navigate=useNavigate()
    const location = useLocation()
    const doubleClickCateRef=useRef(false)
    const isFirstByRouteRef=useRef(true)
    const onSelect = (selectedKeys) => {
        const id=selectedKeys[0]
        let element = document.getElementById(id)
        if(element){
            element.scrollIntoView({ behavior: 'smooth', block: 'center' })
            isFirstByRouteRef.current=false
            navigate(`${location.pathname}#${id}`,{replace:doubleClickCateRef.current})
            doubleClickCateRef.current=true
        }
  }
  useEffect(()=>{
    const hash = location.hash
    if(hash && isFirstByRouteRef.current){
        onSelect([hash.slice(1,hash.length)])
    }
  },[]) //eslint-disable-line
  return <Tree 
  height={300}
  showLine 
  defaultExpandAll={true} 
  defaultExpandParent 
  switcherIcon={<DownOutlined />} 
  onSelect={onSelect} 
  treeData={props.treeData} />
}

export default function CategoryCard(props) {
  return (
      <CategroyList treeData={props.data} />
  )
}
