import React from 'react'

import CategoryCard from '../CategoryCard'
import AuthorCard from '../AuthorCard'
import AuthorInfo from '../AuthorInfo/'

import './style.less'

function Loding(){
  return(
    <p>加载中...</p>
  )
}

export default function index(props) {
  const { categoryList ,author} = props.articleData
  return (
    <div className='active_info_right_fixed'>
      <AuthorCard title="作者生涯" data={author} content={author ? AuthorInfo : Loding}/>
      <AuthorCard title="目录" data={categoryList} content={categoryList ? CategoryCard : Loding} />
    </div>
  )
}
