export const CreditEnum ={
    SYSTEM:{
        SYSTEM:'SYSTEM',
        creditType:1,
        quantity:0,
        message:'系统赠送'
    },
    USER:{
        USER:'USER',
        creditType:2,
        quantity:0,
        message:'用户赠送'
    },
    SIGN_IN:{
        SIGN_IN:'SIGN_IN',
        creditType:3,
        quantity:2,
        message:'签到赠送'
    },
    BROWSE:{
        BROWSE:"BROWSE",
        creditType:4,
        quantity:2,
        message:'阅读文章赠送'
    },
    REVIEW:{
        REVIEW:'REVIEW',
        creditType:5,
        quantity:1,
        message:'评论文章赠送'
    },
    STAR_ARTICLE:{
        STAR_ARTICLE:'STAR_ARTICLE',
        creditType:6,
        quantity:1,
        message:'点赞文章赠送'
    },
    FOLLOW:{
        FOLLOW:'FOLLOW',
        creditType:7,
        quantity:1,
        message:'关注博主赠送'
    },
    LOGIN:{
        LOGIN:'LOGIN',
        creditType:8,
        quantity:1,
        message:'登录赠送'
    }
}