import { Button, List, Skeleton, message } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import { 
  getArticleAll, 
  getArticleByCate, 
  getArticleBySearch ,
  getArticleAllByUser,
  getArticleByStar,
  getArticleByView
} from '../../service/article'
import {getAdvertArticle} from '../../service/credit'

import './style.less'

import ArticleCard from '../../components/ArticleCard'

const count = 10
const App = (props) => {
  const [initLoading, setInitLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isEdit,setIsEdit]=useState(false)
  const [isBuyAdvert,setIsBuyAdvert]=useState(false)
  const [isAdvert,setIsAdvert]=useState(false)
  const [isNullArticleList, setIsNullArticleList] = useState(false)
  const [articleData, setArticleData] = useState([])
  const [list, setList] = useState([])
  const currentRef = useRef(1)
  const { type, id } = props.type_ID;
  
  const getArticleByType = async (type, id, current, size) => {
    let _data, _success
    setIsNullArticleList(false)
    switch (type) {
      case 'default':
        const { data: dataBD, success: successBD } = await getArticleAll({ current, size: 10 })
        _data = dataBD
        _success = successBD
        setIsEdit(false)
        break
      case 'category':
        const { data: dataBC, success: successBC } = await getArticleByCate({ current, size: 10, categoryId: id })
        _data = dataBC
        _success = successBC
        setIsEdit(false)
        break
      case 'search':
        const { data: dataBS, success: successBS } = await getArticleBySearch({ current, size: 10, key: id })
        _data = dataBS
        _success = successBS
        setIsEdit(false)
        break
      case 'user':
        const { data: dataBU, success: successBU } = await getArticleAllByUser({ current, size: 10, key: id })
        _data = dataBU
        _success = successBU
        setIsEdit(true)
        break
      case 'userBuyAdvert':
        const { data: dataBAD, success: successBAD } = await getArticleAllByUser({ current, size: 10, key: id })
        _data = dataBAD
        _success = successBAD
        setIsEdit(false)
        setIsBuyAdvert(true)
        break
      case 'articleByOneself':
        const { data: dataBOS, success: successBOS } = await getArticleAllByUser({ current, size: 10, userId: id })
        _data = dataBOS
        _success = successBOS
        setIsEdit(false)
        break
      case 'articleByStarHistory':
        const { data: dataBSH, success: successBSH } = await getArticleByStar({ current, size: 10, userId: id })
        const newRecords = dataBSH.records.map(item=>{
          return item.article
        })
        _data = {...dataBSH,records:newRecords}
        _success = successBSH
        setIsEdit(false)
        break
      case 'articleByViewHistory':
        const { data: dataBVH, success: successBVH } = await getArticleByView({ current, size: 10, userId: id })
        _data = dataBVH
        _success = successBVH
        setIsEdit(false)
        break
      case 'advert':
        const {data:dataBADV,success:successBADV} =  await getAdvertArticle({ current, size: 10 })
        _data=dataBADV
        _success=successBADV
        setIsEdit(false)
        setIsAdvert(true)
        break
      default:
        break
    }
    return { _data, _success }
  }

  useEffect(() => {
    (async()=>{
        currentRef.current=1
        const { _data, _success } = await getArticleByType(type, id, currentRef.current, 10)
        if (_success) {
          if (_data.pages === _data.current || _data.pages === 0) {
            setIsNullArticleList(true)
          }
          setInitLoading(false)
          setArticleData(_data.records)
          setList([..._data.records])
        }
    })()
  }, [type,id])

  const onLoadMore = () => {
    currentRef.current++
    setLoading(true)
    setList(
      articleData.concat(
        [...new Array(count)].map(() => ({
          loading: true,
          name: {},
          picture: {},
        }))
      )
    );
    (async()=>{
        const { _data, _success } = await getArticleByType(type, id, currentRef.current, 10)
        if (_success) {
          if (_data.pages === _data.current || _data.pages === 0) {
            message.info('已经到底了')
            setIsNullArticleList(true)
          }
            const newData = articleData.concat(_data.records)
            setArticleData(newData)
            setList(newData)
            setLoading(false)
            window.dispatchEvent(new Event('resize'))
        }
    })()
  }

  const loadMore =
    !initLoading && !loading ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button disabled={isNullArticleList} onClick={onLoadMore}>
          加载更多
        </Button>
      </div>
    ) : null
  return (
    <div className="list_box">
      <List
      split={false}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 1,
        md: 1,
        lg: 2,
        xl: 1,
        xxl: 4,
      }}
      className="demo-loadmore-list"
      loading={initLoading}
      itemLayout="horizontal"
      loadMore={loadMore}
      dataSource={list}
      renderItem={(item) => (
        <Skeleton avatar title={false} loading={item.loading} active>
          <List.Item>
             <ArticleCard isBuyAdvert={isBuyAdvert} isAdvert={isAdvert} isEdit={isEdit} item={item} />
          </List.Item>
        </Skeleton>
      )}
    />
    </div>
  )
}

export default App
