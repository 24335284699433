import React,{useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';

import { UserOutlined, ArrowRightOutlined,ClusterOutlined,TeamOutlined ,ReadOutlined,LikeOutlined,FieldTimeOutlined} from '@ant-design/icons';
import { Tabs } from 'antd';

import UserInfo from '../UserInfo'
import FollowList from '../FollowList';
import ArticleList from '../../../../ArticleList'

const App = () =>{
    const {id}=useParams()
    const [activeKey,setActiveKey]=useState('userInfo')
    useEffect(()=>{
        setActiveKey('userInfo')
    },[id])
    
    const tabMap=[
        {
            key:'userInfo',
            label:(
                <span onClick={()=>setActiveKey('userInfo')}>
                    <UserOutlined/>
                    {'博主信息'}
                </span>
            ),
            children:<UserInfo key={Math.random()} id={id}/>
        },
        {
            key:'follow',
            label:(
                <span onClick={()=>setActiveKey('follow')}>
                    <TeamOutlined/>
                    {'博主粉丝'}
                </span>
            ),
            children:<FollowList type="follow" id={id}/>
        },
        {
            key:'concern',
            label:(
                <span onClick={()=>setActiveKey('concern')}>
                    <ClusterOutlined/>
                    {'博主关注'}
                </span>
            ),
            children:<FollowList type="concern" id={id}/>
        },
        {
            key:'articleByOneself',
            index:'004',
            label:(
                <span onClick={()=>setActiveKey('articleByOneself')}>
                    <ReadOutlined/>
                    {'博主文章'}
                </span>
            ),
            children:<ArticleList type_ID={{type:'articleByOneself',id}}/>
        },
        {
            key:'articleByStarHistory',
            label:(
                <span onClick={()=>setActiveKey('articleByStarHistory')}>
                    <LikeOutlined/>
                    {'点赞历史'}
                </span>
            ),
            children:<ArticleList type_ID={{type:"articleByStarHistory",id}}/>
        },
        {
            key:'articleByViewHistory',
            label:(
                <span onClick={()=>setActiveKey('articleByViewHistory')}>
                    <FieldTimeOutlined/>
                    {'阅读历史'}
                </span>
            ),
            children:<ArticleList type_ID={{type:'articleByViewHistory',id}} />
        },
    ]
    return(
        <Tabs 
        tabBarExtraContent={<ArrowRightOutlined style={{color:'#1DA57A'}}/>}
        defaultActiveKey={activeKey}
        tabPosition='top'
        activeKey={activeKey}
            items={tabMap}
        />
      );
} 
export default App;