import React, { useEffect, useState } from 'react';

import {  Table, Tag,message ,Avatar} from 'antd';


import {getUserById} from '../../../../../service/user'

import './style.less'

const columns = [
  {
    title: '昵称',
    dataIndex: 'nickName',
    key: 'nickName',
  },
  {
    title: '性别',
    key: 'sex',
    dataIndex: 'sex',
    render: (_, { sex }) => (
      <>
        {sex.map((tag) => {
          let color = tag === '男' ? 'geekblue' : 'volcano';

          if (tag === '保密') {
            color = 'green';
          }

          return (
            <Tag color={color} key={Math.random()}>
              {tag}
            </Tag>
          );
        })}
      </>
    ),
  }
];

const App = (props) => {
    const [userInfo,setUserInfo]=useState({})
    const tableMapByUserData=[{...userInfo,'sex':[userInfo.sex]}]
    useEffect(()=>{
        ;(async ()=>{
            const {data,success}=await getUserById({userId:props.id})
            if(success){
                setUserInfo({...data})
            }else{
                message.error(data)
            }
        })()
    },[]) //eslint-disable-line
    return (
        <div className='user_info_box'>
        <Avatar size={64} src={userInfo.headImgUrl} />
        <Table 
        rowKey={record=>Math.random()}
        columns={columns} 
        dataSource={tableMapByUserData} 
        pagination={false}
        />
        </div>
    )
};

export default App;