import { Menu } from 'antd'
import {AlignLeftOutlined}  from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {useSelector, useDispatch}from 'react-redux'

import { setCategory } from '../../../../redux/actions/category_action'

import { getCateGorys } from '../../../../service/article'

const App = (props) => {
  const category=useSelector(state=>state.categoryData)
  const dispatch=useDispatch()
  const [current, setCurrent] = useState('')
  const [categorys, setCategorys] = useState(category)
  const navigate=useNavigate()
  const onClick = (e) => {
    setCurrent(e.key)
    navigate(`/home/category/${e.key}`)
  }
  useEffect(() => {
    const getCateGorysFun = async () => {
      const { data, success } = await getCateGorys()
      if (success) {
        const newCate = (cateData, isGroup = false) =>
          cateData.map((item) => {
            return {
              label: item.categoryName,
              key: item.categoryId,
              children: item.children.length > 0 ? [{ children: newCate(item.children)}] : null,
            }
          })
        let newCateData = newCate(data, false)
        newCateData.map((item) => {
          return {
            ...item,
            children: item.children && item.children.map((item2) => {
              const data = item2
              data['type'] = 'group'
              return data
            }),
          }
        })
        dispatch(setCategory([...newCateData]))
        setCategorys([...newCateData])
      }
    }
    !category.length &&  getCateGorysFun()
  }, [])// eslint-disable-line

  return <Menu 
  overflowedIndicator=<AlignLeftOutlined />
  onClick={onClick} 
  selectable={true} 
  selectedKeys={[current]} 
  mode="horizontal" 
  items={categorys} />
}

export default App
