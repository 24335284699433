import React, { useState } from 'react'
import {useSelector} from 'react-redux'

import {Button,message} from 'antd'
import { 
    PlusOutlined,
    MinusOutlined
} from '@ant-design/icons'

import { getFollow ,getUnFollow} from '../../service/user'

import { CreditEnum } from '../../views/PersonalCenter/cpns/TodoList/config/constant'
import { creditLastTimeByType } from '../../utils/creditLastTime'
import { postAddCreditFun } from '../../utils/postAddCredit'

import PubSub from 'pubsub-js'

export default function Index(props) {
  const userData=useSelector(state=>state.user)
  const {userId,followed}=props.data
  const {isAdvert}=props
  const [_followed,setFollowed]=useState(followed)
  const lastCreditTodoListMap=JSON.parse(localStorage.getItem('lastCreditTodoListMap'))
  const getFollowFun=async ()=>{
    if(userData.userId === userId) return message.info('自己不能关注自己')
    const {data,success}=await getFollow({userId:userId})
    if(success){
      if(isAdvert){
        if(lastCreditTodoListMap[CreditEnum.FOLLOW.FOLLOW].nums===2)
        message.info('关注任务已全部完成,请明天再来')
        else{
        postAddCreditFun(
          CreditEnum.FOLLOW.creditType,
          CreditEnum.FOLLOW.quantity,
          CreditEnum.FOLLOW.message,
          CreditEnum.FOLLOW.FOLLOW
          )
          PubSub.publish('followByToList',true)
        creditLastTimeByType(CreditEnum.FOLLOW.FOLLOW,1)
      }
      }
      setFollowed(true)
    }else{
      message.error(data)
    }
  }
  const getUnFollowFun=async ()=>{
    const {data,success}=await getUnFollow({userId:userId})
    if(success){
      setFollowed(false)
    }else{
      message.error(data)
    }
  }
  return (
      !_followed 
      ?<Button type="primary" icon={<PlusOutlined/>} onClick={()=>getFollowFun()}>关注</Button> 
      : <Button  icon={<MinusOutlined />} onClick={()=>getUnFollowFun()}>取消关注</Button>
  )
}
