import React, { useEffect, useState, useRef } from 'react'

import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Row, Col, Input } from 'antd'

import { getCode } from '../../../service/login'

const App = (props) => {
  const [code, setCode] = useState({})
  const formEl = useRef(null)
  const getCodeFun = async () => {
    const { data, success } = await getCode()
    success && setCode({ ...data })
  }
  const onFinish = (values) => {
    props
      .loginFun({
        ...values,
        uuid: code.uuid,
      })
      .then((res) => {
        res && getCodeFun()
      })
  }
  useEffect(() => {
    if (props.id === props.toggleId) {
      getCodeFun()
      formEl.current.setFieldsValue({ ...JSON.parse(localStorage.getItem('name&passowrd')) })
    }
  }, [props])
  return (
    <Form ref={formEl} name="normal_login" className="login-form" onFinish={onFinish}>
      <Form.Item
        name="userName"
        rules={[
          {
            required: true,
            message: '输入用户名',
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
      </Form.Item>
      <Form.Item
        name="passWord"
        rules={[
          {
            required: true,
            message: '输入密码',
          },
        ]}
      >
        <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="输入密码" />
      </Form.Item>
      <Form.Item>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="code" noStyle rules={[{ required: true, message: '输入验证码' }]}>
              <Input placeholder="输入验证码" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <img className="code_box" onClick={getCodeFun} src={code.img} alt="加载失败" />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>记住密码</Checkbox>
        </Form.Item>

        {/* <a className="login-form-forgot" href="">
            忘记密码
          </a> */}
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          登录
        </Button>
      </Form.Item>
    </Form>
  )
}

export default App
