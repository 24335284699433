import { useRoutes } from 'react-router-dom'

import myElements from './router/index'

import './App.less'

function App() {
  const elements = useRoutes(myElements)
  return <div>{elements}</div>
}

export default App
