import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { PlusOutlined } from '@ant-design/icons'
import { Form, Input, Button, Image, Radio, Upload, message } from 'antd'

import Category from './cpns/Category'
import Back from '../../components/Back'
import MyEditor from './cpns/Wang'

import { BASE_URL } from '../../service/request/config'
import { postAddActicle, getArticleDetail, postArticleEdit ,getArticleDel} from '../../service/article'

import './style.less'

const FormDisabledDemo = () => {
  const navigage = useNavigate()
  const [search] = useSearchParams()
  const articleId = search.get('articleId')
  const categoryId = search.get('categoryId')
  const categoryRef=useRef(articleId ? categoryId : false)
  const headerObj = { token: 'zzl_' + localStorage.getItem('token') }
  const [articleData, setArticleData] = useState({})
  const [imgSrc, setImgSrc] = useState('')
  const [isShowOldImg,setIsShowOldImg]=useState(false)
  const [isUseRelusByParent,setIsUseRelusByParent]=useState(true)
  const isDoubleRef=useRef(0)
  const formEl = useRef()
  const onFinish = async (values) => {
    values['coverImg'] = imgSrc
    values['categoryId']=categoryRef.current
    values['articleState']='1'
    const { data, success } = articleId ? await postArticleEdit({ ...values, articleId }) : await postAddActicle({ ...values })
    if (success) {
      message.success('提交成功')
      navigage('/')
    } else {
      message.error(data)
    }
  }
  const normFile = (e: any) => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }
  const checkedCategoryFun = useCallback((key) => {
    categoryRef.current=key
    // formEl.current.setFieldsValue({ categoryId: key, articleState: '1' })
  },[])
  // const checkedCategoryFun = (key) => {
  //   categoryStatusRef.current={ categoryId: key, articleState: '1' }
  //   // formEl.current.setFieldsValue({ categoryId: key, articleState: '1' })
  // }
  useEffect(() => {
    if (articleId) {
      ;(async () => {
        const { data, success } = await getArticleDetail({ articleId })
        if (success) {
          setArticleData({ ...data })
          categoryRef.current=data.categoryId
          setIsShowOldImg(true)
          formEl.current.setFieldsValue({
            articleTitle: data.articleTitle,
            categoryId: data.categoryId,
            articleState: '1',
            articleContent: data.articleContent,
          })
        } else {
          message.error(data)
        }
      })()
    }
  }, [articleId])

  const action = BASE_URL + '/cos/upload'
  const onChange = ({ fileList: newFileList }) => {
    const newFile = newFileList[newFileList.length - 1]
    if (newFile.status === 'done') {
      const { data, success } = newFile.response
      if (success) {
        setIsShowOldImg(false)
        const imgUrl = data.fileBaseUrl + data.filePath
        setImgSrc(imgUrl)
      } else {
        message.error(data)
      }
    }
  }
  const autoFillByNumsLess=new Array(7).fill('内容太少').join('')
  const editContent=(value)=>{
    formEl.current.setFieldsValue({
      articleContent:value.length>=100 ? value : autoFillByNumsLess
    })
  }
  const getArticleDelFun=async ()=>{
    const {data,success}=await getArticleDel({articleId})
    if(success){
      message.success('删除成功')
      navigage(-1)
    }else{
      message.error(data)
    }
  }
  const doubleClick=()=>{
    if(isDoubleRef.current>=2){
      setIsUseRelusByParent(false)
      isDoubleRef.current=0
    }else{
      setIsUseRelusByParent(true)
      isDoubleRef.current=isDoubleRef.current+1
    }
  }
  return (
    <>
      <Back />
      <div className="edit_article_box">
      <Form
      style={{width:'100%'}}
        initialValues={{}}
        ref={formEl}
        onFinish={onFinish}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
      >
        <Form.Item
          label="文章标题"
          rules={[
            {
              required: true,
              message: '文章标题是必填项',
            },
          ]}
          name="articleTitle"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="文章内容"
          name="articleContent"
        >
        {
          articleId 
          ? (articleData.articleContent && <MyEditor editContent={editContent} data={articleData.articleContent}/>)
          : <MyEditor editContent={editContent} data={articleData.articleContent}/>
        }
        </Form.Item>
        <Form.Item label={<span onClick={()=>doubleClick()}>父级分类</span>} name="categoryId">
          <Category isUseRelusByParent={isUseRelusByParent} categoryIdByUser={categoryRef.current} checkedCategoryFun={checkedCategoryFun} />
        </Form.Item>
        <Form.Item label="文章状态" name="articleState">
          <Radio.Group value="1" defaultValue="1">
            <Radio value="1"> 发布 </Radio>
            <Radio value="2"> 草稿 </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item valuePropName="fileList" getValueFromEvent={normFile} name="coverImg" label="上传">
            <span className='preview'>
          {isShowOldImg && <Image width={100} src={articleData.coverImg} /> }&nbsp;&nbsp;
          <Upload maxCount={1} action={action} onChange={onChange} headers={headerObj} listType="picture-card">
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                上传
              </div>
            </div>
          </Upload>
            </span>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <div className="edit_article_btn">
            <Button type="primary" htmlType="submit">
              提交
            </Button>&nbsp;&nbsp;
            {
              articleId &&<Button type="primary" onClick={()=>getArticleDelFun()}>删除</Button>
            }
          </div>
        </Form.Item>
      </Form>
      </div>
    </>
  )
}
// eslint-disable-next-line
export default () => <FormDisabledDemo />
