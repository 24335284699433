import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Drawer, Avatar, List } from 'antd'

import FollowList from '../../../User/UserCollect/cpns/FollowList'
import PubSub from 'pubsub-js'

import { 
    EyeTwoTone, 
    MessageTwoTone, 
    IdcardTwoTone,
    LikeTwoTone
 } from '@ant-design/icons'

import { CreditEnum } from './config/constant'

//推广关注列表
const FllowAdvert = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false)
    useImperativeHandle(ref, () => ({
      showDrawer: () => {
        setOpen(true)
      },
    }))
    const onClose = () => {
      setOpen(false)
    }
    return (
      <>
        <Drawer title="关注博主送积分" placement="top" closable={false} onClose={onClose} open={open} key="credit_todo_fllow_list">
          <FollowList type={CreditEnum.FOLLOW.FOLLOW} id={'0ea74b67691ad317090daeb5e3b53a98'}/>
        </Drawer>
      </>
    )
  })

//任务列表
const TodoListContent = () => {
  const navigate=useNavigate()
  const lastCreditTodoListMap = JSON.parse(localStorage.getItem('lastCreditTodoListMap'))
  const FollowAdvertRef=useRef()
  const [followChange,setFollowChange]=useState(0)
  useEffect(()=>{
    const followTimer = PubSub.subscribe('followByToList',(msg,data)=>{
      setFollowChange(followChange+Math.random())
    })
    return ()=>{
      PubSub.unsubscribe(followTimer)
    }
  },[])//eslint-disable-line
  const ListData = [
    {
      title: '阅读一篇文章 2 积分',
      description: `今日阅读文章任务 ${lastCreditTodoListMap[CreditEnum.BROWSE.BROWSE].nums} / 2`,
      icon: <EyeTwoTone twoToneColor="#1DA57A" />,
      fn:()=>navigate(`/home/advert/1`)
    },
    {
      title: '评论一篇文章 1 积分',
      description: `今日评论文章任务 ${lastCreditTodoListMap[CreditEnum.REVIEW.REVIEW].nums} / 2`,
      icon: <MessageTwoTone twoToneColor="#1DA57A" />,
      fn:()=>navigate(`/home/advert/1`)
    },
    {
        title: '点赞一篇文章 1 积分',
        description: `今日点赞文章任务 ${lastCreditTodoListMap[CreditEnum.STAR_ARTICLE.STAR_ARTICLE].nums} / 2`,
        icon: <LikeTwoTone twoToneColor="#1DA57A" />,
        fn:()=>navigate(`/home/advert/1`)
    },
    {
      title: '关注一位博主 1 积分',
      description: `今日关注博主任务 ${lastCreditTodoListMap[CreditEnum.FOLLOW.FOLLOW].nums} / 2`,
      icon: <IdcardTwoTone  twoToneColor="#1DA57A" />,
      fn:()=>FollowAdvertRef.current.showDrawer()
    }
  ]
  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={ListData}
        renderItem={(item) => (
          <span onClick={() => item.fn && item.fn()}>
            <List.Item>
              <List.Item.Meta avatar={<Avatar size="large" style={{ backgroundColor: '#ffff' }} icon={item.icon} />} title={item.title} description={item.description} />
            </List.Item>
          </span>
        )}
      />
      <FllowAdvert ref={FollowAdvertRef}/>
    </div>
  )
}

const App = (props, ref) => {
  const [open, setOpen] = useState(false)
  useImperativeHandle(ref, () => ({
    showDrawer: () => {
      setOpen(true)
    },
  }))
  const onClose = () => {
    setOpen(false)
  }
  return (
    <>
      <Drawer title="完成任务送积分" placement="top" closable={false} onClose={onClose} open={open} key="credit_todo_list">
        <TodoListContent/>
      </Drawer>
    </>
  )
}
export default forwardRef(App)
