import {notification } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

export const messageRight = (message,description) => {
    notification.open({
      message,
      description,
      icon: (
        <SmileOutlined
          style={{
            color: '#1DA57A',
          }}
        />
      ),
    });
  };