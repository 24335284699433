import React, {  } from 'react'
import {useParams} from 'react-router-dom'


import ArticleList from '../ArticleList'
import AsideCardListRight from './cpns/AsideCardListRight'
import AsideCardListLeft from './cpns/AsideCardListLeft'
import Nav from '../../components/Nav'
import HomeFooter from '../../components/HomeFooter'


import './style.less'
export default function Home() {
  const {type,id}=useParams()
  return (
    <>
    <Nav></Nav>
    <div className='home_box'>
      <aside className='aside_left'>
        <AsideCardListLeft/>
      </aside>
      <main>
        <ArticleList type_ID={{type,id}}/>
      </main>
      <aside className='aside_right'>
        <AsideCardListRight/>
      </aside>
    </div>
      <footer className='home_footer'>
        <HomeFooter/>
      </footer>
    </>
  )
}
