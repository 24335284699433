import React, {} from 'react'

import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input} from 'antd'

import './style.less'

const App = (props) => {
  const onFinish = (values)=>{
    props.registerFun(values)
  }
  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{}}
      onFinish={onFinish}
    >
      <Form.Item
        name="userName"
        rules={[
          {
            required: true,
            message: '输入用户名',
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
      </Form.Item>
      <Form.Item
        name="passWord"
        rules={[
          {
            required: true,
            message: '输入密码',
          },
        ]}
      >
        <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="输入密码" />
      </Form.Item>
      <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>记住密码</Checkbox>
        </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          注册
        </Button>
      </Form.Item>
    </Form>
  )
}

export default App
