
import React, { } from 'react';

import MyInfo from '../../../../components/MyInfo'

import './style.less'

const App = () => {
  return (
    <div className='aside_left_home_box'>
    <MyInfo/>
   </div>
  );
};

export default App;