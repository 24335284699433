import { Avatar, List ,message} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {useSelector} from 'react-redux'

import { 
    PropertySafetyTwoTone,
    ScheduleTwoTone,
    ProfileTwoTone
    } from '@ant-design/icons'

import TodoList from '../TodoList'

import { getCredit} from '../../../../service/credit';

import {CreditEnum} from '../TodoList/config/constant'

import { creditLastTimeByType } from '../../../../utils/creditLastTime';
import { postAddCreditFun } from '../../../../utils/postAddCredit';

const App = () => {
  const user=useSelector(state=>state.user)
  const navigate=useNavigate()
    const [creditNums,setCreditNums]=useState(0)
    const TodoListRef=useRef()
    const lastCreditTodoListMap=JSON.parse(localStorage.getItem('lastCreditTodoListMap'))
    const creditKeys = Object.keys(lastCreditTodoListMap)
    const [doneCreditNums,setDoneCreditNums] = useState(creditKeys.reduce((pre,cur)=>{
      return pre+=(lastCreditTodoListMap[cur].nums>=2 ? 1 : 0)
    },0))
    useEffect(()=>{
      if(!user?.nickName){
        message.error('请先去登录')
        navigate('/login',{replace:true})
        return
      }
        //获取用户总积分
        ;(async()=>{
            const {data,success}=await getCredit()
            if(success){
                setCreditNums(data)
            }else message.error(data)
        })()
    },[])//eslint-disable-line
    //签到送积分
    const _postAddCreditFun=(creditType)=>{
      if(lastCreditTodoListMap[CreditEnum.SIGN_IN.SIGN_IN].nums===2 
        || creditLastTimeByType(CreditEnum.SIGN_IN.SIGN_IN,2)) return message.info('今日已经签到过了')
      const success = postAddCreditFun(creditType,CreditEnum.SIGN_IN.quantity,CreditEnum.SIGN_IN.message,CreditEnum.SIGN_IN.SIGN_IN,2)
      if(success){
        setCreditNums(creditNums+2)
        setDoneCreditNums(doneCreditNums+1)
      }
    }
    
    const ListData = [
        {
            title: '我的积分',
            description:creditNums,
            icon:<PropertySafetyTwoTone twoToneColor="#1DA57A"/>,
            fn:()=>navigate('/credit/center/optionlist')
        },
        {
            title: '每日签到',
            description:lastCreditTodoListMap[CreditEnum.SIGN_IN.SIGN_IN].status==='done' ? '今日已完成签到' : "签到送积分",
            icon:<ScheduleTwoTone twoToneColor="#1DA57A"/>,
            fn:()=>_postAddCreditFun(CreditEnum.SIGN_IN.creditType)
        },
        {
            title: '完成任务送积分',
            description:`今日完成任务 ${doneCreditNums} / ${creditKeys.length}`,
            icon:<ProfileTwoTone twoToneColor="#1DA57A"/>,
            fn:()=>TodoListRef.current.showDrawer()
        },
        
    ];
    return (
        <>
        <List
          itemLayout="horizontal"
          dataSource={ListData}
          renderItem={(item) => (
            <span onClick={()=>item.fn && item.fn()}>
              <List.Item>
              <List.Item.Meta
                avatar={<Avatar size="large" style={{'backgroundColor': '#ffff'}} icon={item.icon} />}
                title={item.title}
                description={item.description}
              />
            </List.Item>
            </span>
          )}
        />
        <TodoList ref={TodoListRef}/>
        </>
      );
}
export default App;