import {  Button, List, Skeleton ,message} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useParams ,useSearchParams} from 'react-router-dom';
import PubSub from 'pubsub-js'

import CommentItem from '../CommentItem'

import { getComment } from '../../../../../../service/comment';
import { CreditEnum } from '../../../../../PersonalCenter/cpns/TodoList/config/constant';
import { postAddCreditFun } from '../../../../../../utils/postAddCredit';
import { creditLastTimeByType } from '../../../../../../utils/creditLastTime';

import './style.less'

const size = 10;
const App = (props) => {
  const {id:articleId}=useParams()
  const [search,]=useSearchParams()
  const isAdvert=search.get('advert')
  const [isNullCommentList,setIsNullCommentList]=useState(false)
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true)
  const [data, setData] = useState(props.childCommentList || []);
  const [list, setList] = useState(props.childCommentList || []);
  const delListRef=useRef([])
  const currentRef=useRef(1)
  const lastCreditTodoListMap=JSON.parse(localStorage.getItem('lastCreditTodoListMap'))

  useEffect(() => {
    ;(async ()=>{
        if(props.childCommentList!==undefined) return
        const {data,success}=await getComment({articleId,current:currentRef.current,size})
        if(success){
            if (data.pages === data.current || data.pages === 0) {
                setIsNullCommentList(true)
            }
            setInitLoading(false)
            setData(data.records)
            setList(data.records)
            console.log('data',data.records)
        }else message.error(data)
    })()
  }, [articleId]);//eslint-disable-line
  const _postAddCreditFun=()=>{
    if(!isAdvert || lastCreditTodoListMap[CreditEnum.REVIEW.REVIEW].status==='done') return
    if(isAdvert && lastCreditTodoListMap[CreditEnum.REVIEW.REVIEW].nums===2) return message.info('今日评论任务已完成,明天再来吧')
    postAddCreditFun(
      CreditEnum.REVIEW.creditType,
      CreditEnum.REVIEW.quantity,
      CreditEnum.REVIEW.message,
      CreditEnum.REVIEW.REVIEW
      )
    creditLastTimeByType(CreditEnum.REVIEW.REVIEW)
  }
  useEffect(()=>{
    const addTimer=PubSub.subscribe('addFirstComment',(msg,_data)=>{
        const {data:_newData}=_data
        _newData.commentContent=_data.commentContent
        const newData = [_newData,...list]
        _postAddCreditFun()
        setData(newData);
        setList(newData);
    })
    return ()=>{
        PubSub.unsubscribe(addTimer)
    }
})
    const delComment=(_data)=>{
        delListRef.current.push(_data.commentId)
        const newData = [...list]
        setData(newData);
        setList(newData);
    }
    const editComment=(_data)=>{
        let index = null
        // eslint-disable-next-line
        list.some((item,_index)=>{
            if(item.commentId===_data.commentId){
                index=_index
                return true
            }
        })
        if(index!==null){
            list[index].commentContent=_data.commentContent
            const newData = [...list]
            setData(newData);
            setList(newData);
        }
    }
    const replyComment=(_data,parentUser)=>{
        let index = null
        // eslint-disable-next-line
        list.some((item,_index)=>{
            if(item.author.userId===parentUser.userId){
                index=_index
                return true
            }
        })
        if(index!==null){
          _postAddCreditFun()
          list[index].children.unshift(_data)
          const newData = [...list]
          setData(newData);
          setList(newData);
        }
    }
  
  const onLoadMore = () => {
    currentRef.current++
    setLoading(true);
    setList(
      data.concat(
        [...new Array(size)].map(() => ({
          loading: true,
          name: {},
          picture: {},
        })),
      ),
    );
    (async()=>{
        const {data:_data,success}=await getComment({articleId,current:currentRef.current,size})
        if(success){
            if (_data.pages === _data.current || _data.pages === 0) {
                setIsNullCommentList(true)
              }
            const newData = data.concat(_data.records);
            setData(newData);
            setList(newData);
            setLoading(false);
            window.dispatchEvent(new Event('resize'));
        }else message.error(data)
    })()
  };
  return (
    <div className="comment_box">
        <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={list}
          bordered={false}
          renderItem={(item) => (
            !delListRef.current.includes(item.commentId) && <List.Item key={item.commentId}>
              <Skeleton loading={item.loading} active>
                <List.Item.Meta 
                description={
                <CommentItem 
                    parentUser={props.parentUser} 
                    delComment={delComment} 
                    editComment={editComment} 
                    replyComment={replyComment}
                    commentData={item}/>
                } />
              </Skeleton>
            </List.Item>
          )}
        />
        <div className="loadmore_box">
        {props.childCommentList===undefined && !loading && 
        <Button loading={initLoading} block disabled={isNullCommentList} onClick={onLoadMore}>加载更多</Button>}
        </div>
    </div>
  );
};
export default App;