
import React, { } from 'react';

import {LikeOutlined, EyeOutlined, MessageOutlined } from '@ant-design/icons'

import AsideCard from '../../../../components/AsideCard'

import './style.less'

const App = () => {
  return (
    <div className='aside_right_home_box'>
    <AsideCard  iconNode={<LikeOutlined/>} orderBy="articleStar" title="点赞量排行"/>
    <AsideCard iconNode={<MessageOutlined/>} orderBy="articleComments" title="评论量排行"/>
    <AsideCard iconNode={<EyeOutlined/>}  orderBy="articleViews" title="阅读量排行"/>
    </div>
  );
};

export default App;