import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from 'antd'

import Menu from './cpns/Menu'
import Search from './cpns/Search'
import Avatar from './cpns/Avatar'

import './style.less'

export default function Nav() {
  const navigate = useNavigate()
  return (
    <div className="nav_box">
      <header>
        <div
          className="logo"
          onClick={() => {
            navigate('/home/default/default')
          }}
        >
          享码
        </div>
        <div className="menu">
          <Menu />
        </div>
        <div className="search">
          <Search />
        </div>
        <div className="user">
          <Avatar />
        </div>
        <div className="create_article">
          <Button type="primary" onClick={() => navigate('/article/edit')}>
            创建文章
          </Button>
        </div>
      </header>
    </div>
  )
}
