import myAxios from "../request/http";

const Comment = {
    postComment:'/comment',
    getComment:'/comment/get',
    getStarComment:'/comment/star',
    getUnStarComment:'/comment/unStar',
    postEditComment:'/comment/edit',
    getDelComment:'/comment/del',
}

export function getDelComment(data){
    return myAxios({
        url:Comment.getDelComment,
        method:'get',
        isToken:true,
        data
    })
}

export function postEditComment(data){
    return myAxios({
        url:Comment.postEditComment,
        method:'post',
        isToken:true,
        data
    })
}

export function postComment(data){
    return myAxios({
        url:Comment.postComment,
        method:'post',
        isToken:true,
        data
    })
}

export function getComment(data){
    return myAxios({
        url:Comment.getComment,
        method:'get',
        isToken:true,
        data
    })
}

export function getStarComment(data){
    return myAxios({
        url:Comment.getStarComment,
        method:'get',
        isToken:true,
        data
    })
}

export function getUnStarComment(data){
    return myAxios({
        url:Comment.getUnStarComment,
        method:'get',
        isToken:true,
        data
    })
}