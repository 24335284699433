import React from 'react'

import { Divider } from 'antd';

import CommentFirst from './cpns/ComentFirst'
import CommentList from './cpns/CommentList'

import './style.less'

export default function Comment() {
  return (
    <div id='article_comment'>
        <Divider plain={true} orientation="left">评论</Divider>
        <div className="comment_first_input">
            <CommentFirst/>
        </div>
        <div className="comment_list_box">
            <CommentList/>
        </div>
    </div>
  )
}
